.ecosystem {
  &__cover {
    padding-top: 63px;
    padding-bottom: 5px;
    @media screen and (min-width: 990px) {
      padding-top: 177px;
    }
  }
  &__title {
    margin-bottom: 32px;
    @media screen and (min-width: 990px) {
      margin-bottom: 80px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media screen and (min-width: 768px) {
      margin: 0 -17px;
    } 
  }
  &__item {
    padding: 0 10px;
    margin-bottom: 20px;
    width: 100%;
    @media screen and (min-width: 600px) {
      width: 50%; 
    } 
    @media screen and (min-width: 768px) {
      padding: 0 17px;
      margin-bottom: 34px;
    } 
    @media screen and (min-width: 990px) {
      width: 33.3333333333%;
    }
    &_big {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 100%; 
      } 
      @media screen and (min-width: 990px) {
        width: 66.6666666666%;
      }
    }
  }
  &__content {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    } 
    p {
      color: $color-white;
      font: $bold 16px / 1.31 $font;
      margin-bottom: 0;
      @media screen and (min-width: 600px) {
        font-size: 20px;
      }
      @media screen and (min-width: 768px) {
        font-size: 28px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 30px;
      }
    }
  }
  &__text {
    overflow: hidden;
    position: relative;
    padding: 2px;
    border-radius: 21px;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg,#27e55c 0%, #587bff 50%, #b518ff 100%);
      z-index: -1;
    }
    &-content {
      border-radius: 21px;
      padding: 22px 20px 10px;
      background: $color-black;
      @media screen and (min-width: 768px) {
        padding: 32px 25px 10px;
      }
      p {
        color: $color-white;
        font: $semi-bold 14px / 1.33 $font;
        margin-bottom: 18px;
        @media screen and (min-width: 768px) {
          font-size: 15px;
          margin-bottom: 25px;
        }
      }
    }
  }
}