.roadmap {
  &__title {
    position: relative;
    text-align: left;
    color: $color-white;
    font: $bold 35px / 1 $font;
    margin-bottom: 30px;
    padding-right: 50px;
    @media screen and (min-width: 768px) {
      font-size: 40px;
    }
    @media screen and (min-width: 990px) {
      font-size: 45px;
      font-weight: $light;
      text-align: center;
      padding: 0 15px;
      margin-bottom: 55px;
    }
  }
  &__wrapper {
    position: relative;
    padding-bottom: 23px;
    @media screen and (min-width: 990px) {
      padding-bottom: 115px;
    }
  }
  &__line {
    position: absolute;
    left: 0;
    top: 88px;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #30FD82 0%, #7F00FF 47.4%, #E1169C 76.04%, #FDCA18 100%);
    @media screen and (min-width: 990px) {
      top: 110px;
    }
  }
  &__list {
    display: flex;
    overflow-y: auto;
    max-width: 1920px;
    margin: 0 auto;
    @media screen and (min-width: 990px) {
      padding: 0 30px;
    }
  }
  &__item {
    margin: 0 15px;
    flex: 0 0 auto;
    max-width: 260px;
    @media screen and (min-width: 990px) {
      max-width: 320px;
      margin: 0 30px;
      width: 320px!important;
    }
  }
}