.products {
  &__cover {
    padding-top: 15px;
    padding-bottom: 55px;
  }
  &__head {
    margin-bottom: 32px;
    @media screen and (min-width: 990px) {
      margin-bottom: 40px;
    }
  }
  &__title {
    max-width: none;
    margin-bottom: 0;
    @media screen and (min-width: 990px) {
      text-align: center;
    }
  }
  &__description {
    font-size: 16px;
    color: #717584;

    @media screen and (min-width: 990px) {
      font-size: 24px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 15px;
    @media screen and (min-width: 990px) {
      margin: 0 -16px 50px;
    }
  }
  &__item {
    margin-bottom: 21px;
    padding: 0 10px;
    width: 100%;
    @media screen and (min-width: 600px) {
      width: 50%;
    }
    @media screen and (min-width: 990px) {
      width: 33.3333333333%;
      margin-bottom: 32px;
      padding: 0 16px;
      //&:nth-child(3n+2) {
      //  margin-top: -46px;
      //}
      //&:nth-child(3n+3) {
      //  margin-top: -98px;
      //}
    }
  }
}