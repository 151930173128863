.constituent {
  position: relative;
  padding-left: 20px;
  @media screen and (min-width: 990px) {
    padding-left: 28px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 4px;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    @media screen and (min-width: 990px) {
      width: 15px;
      height: 15px;
      top: 7px;
    }
  }
  &_orange {
    &:before {
      background: #FF650E;
    }
  }
  &_white {
    &:before {
      background: $color-white;
    }
  }
  &_cyan {
    &:before {
      background: #3D96FF;
    }
  }
  &_blue {
    &:before {
      background: #004EE4;
    }
  }
  &_pink {
    &:before {
      background: #FC67FF;
    }
  }
  &_green {
    &:before {
      background: #00BA28;
    }
  }
  &_mint {
    &:before {
      background: #00DF9C;
    }
  }
  &_yellow {
    &:before {
      background: #FBBC04;
    }
  }
  &_red {
    &:before {
      background: #EA0A83;
    }
  }
  &_purple {
    &:before {
      background: #7D0AFC;
    }
  }
  &_grey {
    &:before {
      background: #C9CED6;
    }
  }
  &__count {
    color: $color-white;
    font: $light 19px / 1 $font;
    margin-bottom: 11px;
    text-transform: uppercase;
    @media screen and (min-width: 990px) {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }
  &__title {
    color: $color-white;
    font: $semi-bold 14px / 1 $font;
    margin-bottom: 10px;
    @media screen and (min-width: 990px) {
      font-size: 18px;
    }
  }
  &__description {
    color: $color-white;
    font: $regular 13px / 1.3 $font;
    margin-bottom: 15px;
  }
}