.tabs-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  &__item {
    display: block;
    width: 100%;
    padding: 0 4px;
    margin-bottom: 8px;
    @media screen and (min-width: 480px) {
      width: 50%;
    }
    @media screen and (min-width: 990px) {
      width: 25%;
    }
  }
  &__link {
    display: block;
    text-decoration: none;
    background-color: #1E1F2A;
    border-radius: 12px;
    height: 100%;
    text-align: center;
    padding: 12px 6px 6px;
    transition: background-color 0.3s ease;
    min-height: 57px;
    @media screen and (min-width: 990px) {
      padding: 24px 12px 12px;
    }
    &.active {
      background-color: #1D59F6;
    }
  }
  &__title {
    color: $color-white;
    font: $bold 14px / 1.4 $font;
  }
  &__subtitle {
    color: #7A7B81;
    font: $bold 14px / 1.4 $font;
  }
  &__run {
    display: flex;
    justify-content: center;
    &-icon {
      width: 12px;
      height: 16px;
      margin-right: 5px;
    }
  }
  &__icon {
    display: inline-block;
    width: 20px;
    height: 19px;
  }
}