.manifest {
  position: relative;
  padding-top: 30px;
  padding-bottom: 40px;
  @media screen and (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 88px;
  }
  @media screen and (min-width: 990px) {
    padding-bottom: 150px;
  }
  &__rax {
    position: absolute;
    width: 180%;
    top: 8%;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    z-index: -1;
    @media screen and (min-width: 990px) {
      width: 2152px;
      bottom: 0;
      top: auto;
    }
  }
  &__head {
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      margin-bottom: 50px;
    }
  }
  &__title {
    font-weight: $bold;
    margin-bottom: 5px;
    max-width: unset;
    @media screen and (min-width: 768px) {
      margin-bottom: 15px;
    }
  }
  &__description {
    text-align: center;
    font: $regular 13px / 1 $font;
    color: #717584;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
    } 
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -9px;
    @media screen and (min-width: 1200px) {
      margin: 0 -17px;
    } 
  }
  &__item {
    padding: 0 9px;
    width:  100%;
    margin-bottom: 18px;
    @media screen and (min-width: 600px) {
      width:  50%;
    }
    @media screen and (min-width: 990px) {
      width: 33.3333333333%;
    }
    @media screen and (min-width: 1200px) {
      padding: 0 17px;
      margin-bottom: 34px;
    } 
  }
  &__block {
    max-width: 979px;
    margin: 0 auto;
    padding: 30px 24px 15px;
    background: rgba(0, 13, 39, 0.65);
    backdrop-filter: blur(60px);
    border-radius: 46px;
    @media screen and (min-width: 768px) {
      padding: 40px 48px 30px;
    }
  }
  &__block-title {
    text-align: center;
    color: $color-white;
    font: $extra-light 25px / 1 $font;
    margin-bottom: 18px;
    @media screen and (min-width: 480px) {
      font-size: 40px;
    }
    @media screen and (min-width: 990px) {
      font-size: 55px;
      margin-bottom: 30px;
    }
  }
  &__text {
    margin-bottom: 35px;
    p {
      color: $color-white;
      font: $regular 13px / 1.5 $font;
      margin-bottom: 21px;
      @media screen and (min-width: 768px) {
        font-size: 17px;
        margin-bottom: 27px;
      }
    }
  }
  &__footer {
    margin: 0 -13px;
    @media screen and (min-width: 768px) {
      display: flex;
    }
  }
  &__link-wrapper {
    padding: 0 13px;
    flex: 0 0 auto;
    margin-bottom: 20px;
  }
  &__link {
    display: block;
    text-decoration: none;
    color: $color-white;
    font: $bold 14px / 1.3 $font;
    text-align: center;
    background: linear-gradient(91.81deg, #7520FF 0.6%, #AF1FFB 102.02%);
    border-radius: 15px;
    padding: 15px 26px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  &__followers {
    padding: 0 13px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &-list {
      margin: 0 -3px;
      display: flex;
      position: relative;
      width: 80%;
      @media screen and (min-width: 768px) {
        max-width: 297px;
      }
      @media screen and (min-width: 990px) {
        max-width: 550px;
      }
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -3px;
        width: 31px;
        height: 31px;
        background: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, #040C1B 89.06%, rgba(196, 196, 196, 0) 100%);
        z-index: 1;
        opacity: 0.9;
        @media screen and (min-width: 990px) {
          border-radius: 50%;
          left: 0px;
          width: 38px;
          height: 38px;
          background: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, #0A5456 89.06%, rgba(196, 196, 196, 0) 100%);
        }
      }
      &:after {
        left: auto;
        right: -3px;
        transform: rotate(180deg);
        @media screen and (min-width: 990px) {
          background: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, #081F2E 89.06%, rgba(196, 196, 196, 0) 100%);
          left: auto;
          right: 1px;
        } 
      }
    }
    &-item {
      margin: 0 3px;
      position: relative;
      flex: 0 0 auto;
    }
    &-img {
      max-width: none;
      width: 31px;
      height: 31px;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      @media screen and (min-width: 990px) {
        width: 38px;
        height: 38px;
      } 
    }
  }
  &__count-block {
    text-align: center;
    color: $color-white;
    margin-left: 20px;
    @media screen and (min-width: 990px) {
      margin-left: 44px;
    }
  }
  &__count {
    font: $bold 12px / 1 $font;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    &-text {
      font: $medium 11px / 1 $font;
      @media screen and (min-width: 768px) {
        font-size: 15px;
      }
    }
  }
}