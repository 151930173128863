.extended-apps {
  &__wrapper {
    padding-bottom: 5px;
    @media screen and (min-width: 990px) {
      display: flex;
      justify-content: space-between;
      padding-bottom: 65px;
    }
    @media screen and (min-width: 1360px) {
      margin-left: -345px;
      padding-bottom: 10px;
    }
  }
  &_collection &__wrapper {
    justify-content: space-around;
    padding-top: 35px;
    padding-bottom: 5px;
    @media screen and (min-width: 1200px) {
      padding-bottom: 50px;
    }
    @media screen and (min-width: 1360px) {
      margin-left: 0;
    }
  }
  &__img-block {
    margin-right: -10%;
    @media screen and (min-width: 990px) {
      margin-right: 0;
    }
    @media screen and (min-width: 1360px) {
      flex: 0 0 auto;
      margin-right: -150px;
    }
  }
  &_collection &__img-block {
    margin-right: 0;
    @media screen and (min-width: 1360px) {
      margin-right: 0;
    }
  }
  &__img-wrapper {
    max-width: 1256px;
    width: 100%;
    margin-bottom: -5%;
    @media screen and (min-width: 990px) {
      margin-bottom: 0;
    }
  }
  &_collection &__img-wrapper {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 990px) {
      width: 100%;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__text-block {
    @media screen and (min-width: 990px) {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }
  }
  &__content {
    text-align: center;
    margin: 0 auto 50px;
    max-width: 489px;
    @media screen and (min-width: 990px) {
      text-align: left;
      margin: 0;
    }
  }
  &__title {
    margin-bottom: 20px;
    @media screen and (min-width: 990px) {
      text-align: left;
      margin-bottom: 32px;
    }
  }
  &__text {
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
    p {
      color: #717584;
      font: $regular 13px / 1.56 $font;
      margin-bottom: 16px;
      @media screen and (min-width: 768px) {
        margin-bottom: 25px;
      }
      @media screen and (min-width: 990px) {
        font-size: 16px;
      }
    }
    b {
      color: $color-white;
      font-size: 15px;
    }
  }
  &__links {
    display: flex;
    margin: 0 -8px;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (min-width: 990px) {
      justify-content: flex-start;
    } 
  }
  &__link-wrapper {
    padding: 0 8px;
    margin-bottom: 10px;
    width: 100%;
    @media screen and (min-width: 375px) {
      width: auto;
    }
  }
  &__link {
    display: block;
    text-decoration: none;
    text-align: center;
    color: $color-white;
    font: $bold 14px / 1.2 $font;
    background: rgba(75, 79, 101, 0.32);
    border-radius: 15px;
    padding: 16px 23px;
    min-width: 160px;
    @media screen and (min-width: 480px) {
      min-width: 217px;
    }
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    &_violet {
      background: linear-gradient(91.81deg, #5874C8 0.6%, #AF1FFB 102.02%);
    }
  }
}