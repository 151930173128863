.schedule {
  &__wrapper {
    padding-top: 60px;
    padding-bottom: 25px;
    @media screen and (min-width: 990px) {
      padding-bottom: 100px;
      padding-top: 150px;
    }
  }
  &__title {
    text-align: center;
    color: $color-white;
    font: $bold 25px / 1.1 $font;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
    @media screen and (min-width: 990px) {
      font-size: 40px;
    }
  }
  &__chart {
    position: relative;
    margin: auto;
    width: 100%;
    margin-top: 10px;
    height: 600px;
    margin-bottom: 25px;
    @media screen and (min-width: 768px) {
      margin-top: 40px;
    }
  }
  &__full {
    text-align: center;
    margin-bottom: 30px;
    @media screen and (min-width: 990px) {
      display: none;
    }
  }
}