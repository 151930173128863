.table {
  background: #1E1F24;
  border-radius: 28px;
  padding: 25px 0 0;
  @media screen and (min-width: 990px) {
    padding: 12px;
  }
  &__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    color: $color-white;
    font: $light 19px / 1.4 $font;
    @media screen and (min-width: 990px) {
      font-size: 15px;
      font-weight: $medium;
    }
  }
  &__head {
    display: none;
    @media screen and (min-width: 990px) {
      display: table-header-group;
    }
  }
  &__head &__cell {
    text-align: left;
    padding: 15px 25px 30px;
    font-weight: $bold;
    font-size: 16px;
    white-space: nowrap;
  }
  &__body &__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @media screen and (min-width: 990px) {
      display: table-row;
      margin-bottom: 0;
    }
  }
  &__body &__cell:first-child {
    order: 1;
    width: 100%;
    font-size: 14px;
    font-weight: $bold;
    @media screen and (min-width: 990px) {
      order: 0;
      width: auto;
    }
  }
  &__body &__cell:last-child {
    width: 100%;
    color: #717584;
    order: 2;
    font-weight: $regular;
    @media screen and (min-width: 990px) {
      order: 0;
      color: $color-white;
      width: auto;
    }
  }
  &__body &__cell {
    padding: 2px 25px;
    @media screen and (min-width: 990px) {
      background: #0F1017;
      padding: 9px 25px;
    }
  }
  &__footer &__row {
    display: flex;
    padding: 0 20px;
    @media screen and (min-width: 990px) {
      display: table-row;
      padding: 0;
    }
  }
  &__footer &__cell {
    padding: 5px 5px 25px;
    font-weight: $bold;
    font-size: 17px;
    @media screen and (min-width: 990px) {
      padding: 25px;
    }
  }
  &__body &__cell:first-child {
    font-weight: $bold;
  }
  &__body &__cell:last-child {
    font-size: 11px;
    @media screen and (min-width: 990px) {
      font-size: 14px;
    }
  }
  &__body &__row:first-child &__cell {
    padding: 2px 25px;
    @media screen and (min-width: 990px) {
      padding: 25px 25px 9px;
    }
  }
  &__body &__row:last-child &__cell {
    padding: 2px 25px;
    @media screen and (min-width: 990px) {
      padding: 9px 25px 25px;
    }
  }
  &__body &__row:first-child &__cell:first-child {
    border-radius: 24px 0 0 0;
  }
  &__body &__row:first-child &__cell:last-child {
    border-radius: 0 24px 0 0;
  }
  &__body &__row:last-child &__cell:first-child {
    border-radius: 0 0 0 24px;
  }
  &__body &__row:last-child &__cell:last-child {
    border-radius: 0 0 24px 0;
  }
}