.architecture {
  &__title {
    margin-bottom: 17px;
    @media screen and (min-width: 990px) {
      margin-bottom: 60px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
    @media screen and (min-width: 990px) {
      flex-direction: row;
      padding-bottom: 30px;
    }
  }
  &__img-block {
    flex: 0 0 auto;
    order: -1;
    margin-bottom: -10%;
    @media screen and (min-width: 990px) {
      order: 0;
      margin-left: -16%;
      margin-bottom: 0;
    }
  }
  &__img-wrapper {
    width: 100%;
    @media screen and (min-width: 990px) {
      width: 840px;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (min-width: 990px) {
      display: block;
    }
    &-item {
      width: 100%;
      margin-bottom: 20px;
      @media screen and (min-width: 375px) {
        width: 50%;
      }
      @media screen and (min-width: 990px) {
        width: auto;
        margin-bottom: 33px;
      }
      &_blue {
        order: 1;
        @media screen and (min-width: 990px) {
          order: 0;
          margin-bottom: 37px;
        }
      }
      &_green {
        @media screen and (min-width: 990px) {
          margin-bottom: 66px;
        }
      }
      &_violet {
        order: 2;
        @media screen and (min-width: 990px) {
          order: 0;
        }
      }
    }
  }
  &__text {
    position: relative;
    font: $regular 13px / 1.2 $font;
    color: $color-white;
    padding: 18px 14px;
    @media screen and (min-width: 600px) {
      margin-left: 60px;
    }
    @media screen and (min-width: 768px) {
      font-size: 16px;
      padding: 25px 20px;
    }
    @media screen and (min-width: 990px) {
      margin-left: 0;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 19px;
      background: url('../images/content/border_1.svg') top left/cover no-repeat;
      @media screen and (min-width: 768px) {
        width: 26px;
      }
      .architecture__list-item_blue & {
        background: url('../images/content/border_2.svg') top left/cover no-repeat;
      }
      .architecture__list-item_green & {
        background: url('../images/content/border_3.svg') top left/cover no-repeat;
      }
      .architecture__list-item_violet & {
        background: url('../images/content/border_4.svg') top left/cover no-repeat;
      }
    }
  }
}