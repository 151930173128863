@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}
.logo {
  display: block;
  text-decoration: none;
}
.logo__img {
  max-width: none;
  width: 100%;
}

.advantage__img-wrapper {
  margin-bottom: 24px;
  width: 104px;
}
.advantage__img {
  max-width: none;
  width: 100%;
}
.advantage__title {
  color: #FFF;
  font: 700 16px/1.3 "Work Sans", sans-serif;
  margin-bottom: 13px;
}
@media screen and (min-width: 768px) {
  .advantage__title {
    font-size: 18px;
  }
}
.advantage__description {
  color: #717584;
  font: 400 13px/1.3 "Work Sans", sans-serif;
  margin-bottom: 13px;
}
@media screen and (min-width: 768px) {
  .advantage__description {
    font-size: 14px;
  }
}
.advantage__icons {
  position: relative;
  min-height: 115px;
}
.advantage__big-icon {
  opacity: 0.2;
}
.advantage__small-icon {
  position: absolute;
  top: 41px;
  left: 0;
}

.case {
  position: relative;
  height: 100%;
}
.case::before {
  content: "";
  display: block;
  position: absolute;
  top: -30px;
  height: 120%;
  width: 160%;
  opacity: 0.4;
  filter: blur(110px);
  border-radius: 50%;
  left: 54px;
  z-index: -1;
}
.case_blue:before {
  background: #275BDF;
}
@media screen and (min-width: 768px) {
  .case_blue:before {
    right: 54px;
  }
}
.case_violet:before {
  background: #7F00FF;
  left: 54px;
}
.case_green:before {
  background: #00CA75;
}
@media screen and (min-width: 768px) {
  .case_green:before {
    right: 54px;
  }
}
.case_orange:before {
  background: #FF7448;
  left: 54px;
}
.case__content {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.89) 100%);
  backdrop-filter: blur(75px);
  border-radius: 25.9255px;
  padding: 28px 23px 16px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .case__content {
    padding: 28px 28px 34px;
  }
}
.case__title {
  color: #FFF;
  font: 700 25px/1.2 "Work Sans", sans-serif;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .case__title {
    font-size: 32px;
    margin-bottom: 27px;
  }
}
.case__text p {
  color: #FFF;
  font: 400 13px/1.3 "Work Sans", sans-serif;
  margin-bottom: 18px;
}
@media screen and (min-width: 768px) {
  .case__text p {
    font-size: 14px;
  }
}

.constituent {
  position: relative;
  padding-left: 20px;
}
@media screen and (min-width: 990px) {
  .constituent {
    padding-left: 28px;
  }
}
.constituent:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
  width: 11px;
  height: 11px;
}
@media screen and (min-width: 990px) {
  .constituent:before {
    width: 15px;
    height: 15px;
    top: 7px;
  }
}
.constituent_orange:before {
  background: #FF650E;
}
.constituent_white:before {
  background: #FFF;
}
.constituent_cyan:before {
  background: #3D96FF;
}
.constituent_blue:before {
  background: #004EE4;
}
.constituent_pink:before {
  background: #FC67FF;
}
.constituent_green:before {
  background: #00BA28;
}
.constituent_mint:before {
  background: #00DF9C;
}
.constituent_yellow:before {
  background: #FBBC04;
}
.constituent_red:before {
  background: #EA0A83;
}
.constituent_purple:before {
  background: #7D0AFC;
}
.constituent_grey:before {
  background: #C9CED6;
}
.constituent__count {
  color: #FFF;
  font: 300 19px/1 "Work Sans", sans-serif;
  margin-bottom: 11px;
  text-transform: uppercase;
}
@media screen and (min-width: 990px) {
  .constituent__count {
    font-size: 26px;
    margin-bottom: 15px;
  }
}
.constituent__title {
  color: #FFF;
  font: 600 14px/1 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 990px) {
  .constituent__title {
    font-size: 18px;
  }
}
.constituent__description {
  color: #FFF;
  font: 400 13px/1.3 "Work Sans", sans-serif;
  margin-bottom: 15px;
}

.roadmap-item {
  position: relative;
  padding-top: 30px;
}
@media screen and (min-width: 990px) {
  .roadmap-item {
    padding-top: 45px;
  }
}
.roadmap-item__year {
  color: #FFF;
  font: 300 25px/1 "Work Sans", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 990px) {
  .roadmap-item__year {
    font-size: 38px;
  }
}
.roadmap-item__quarter {
  color: #FFF;
  font: 700 20px/1.3 "Work Sans", sans-serif;
  margin-bottom: 15px;
}
@media screen and (min-width: 990px) {
  .roadmap-item__quarter {
    font-size: 24px;
  }
}
.roadmap-item__point {
  background: #FFF;
  border: 14px solid #090A0E;
  backdrop-filter: blur(41.2903px);
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: -8px;
}
@media screen and (min-width: 990px) {
  .roadmap-item__point {
    width: 43px;
    height: 43px;
    border-width: 17px;
    margin-bottom: 35px;
  }
}
.roadmap-item__list {
  margin-bottom: 27px;
}
.roadmap-item__list-item {
  position: relative;
  color: #FFF;
  font: 400 13px/1.3 "Work Sans", sans-serif;
  padding-left: 20px;
  margin-bottom: 10px;
}
@media screen and (min-width: 990px) {
  .roadmap-item__list-item {
    font-size: 15px;
  }
}
.roadmap-item__list-item:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #FFF;
}
@media screen and (min-width: 990px) {
  .roadmap-item__list-item:before {
    top: 7px;
  }
}

.person {
  background: rgba(31, 33, 45, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 27px 23px 22px;
  height: 100%;
}
@media screen and (min-width: 1200px) {
  .person {
    padding: 27px;
  }
}
.person__head {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
}
.person__img-wrapper {
  width: 122px;
  height: 122px;
  margin: 0 15px 30px 15px;
  flex: 0 0 auto;
}
@media screen and (min-width: 1200px) {
  .person__img-wrapper {
    width: 218px;
    height: 218px;
    margin: -85px 15px 30px 15px;
  }
}
.person__img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.person__socials {
  margin: 0 15px;
}
.person__name {
  color: #FFF;
  font: 700 25px/1 "Work Sans", sans-serif;
  margin-bottom: 18px;
}
@media screen and (min-width: 1200px) {
  .person__name {
    font-size: 30px;
  }
}
.person__position {
  color: #40FF99;
  font: 700 14px/1 "Work Sans", sans-serif;
  margin-bottom: 21px;
}
@media screen and (min-width: 1200px) {
  .person__position {
    font-size: 18px;
  }
}
.person__text p {
  color: #FFF;
  font: 400 13px/1.4 "Work Sans", sans-serif;
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) {
  .person__text p {
    font-size: 15px;
  }
}

.socials {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px;
}
.socials__item {
  padding: 0 9px;
  margin-bottom: 16px;
}
.socials__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #FFF;
}
@media screen and (min-width: 1200px) {
  .socials__link {
    width: 32px;
    height: 32px;
  }
}
.socials__link_nobg {
  background: transparent;
}
.socials__icon {
  fill: #131418;
}
.socials__icon_linkedIn {
  width: 16px;
  height: 15px;
}
@media screen and (min-width: 1200px) {
  .socials__icon_linkedIn {
    width: 18px;
    height: 17px;
  }
}
.socials__icon_twitter {
  width: 16px;
  height: 13px;
}
@media screen and (min-width: 1200px) {
  .socials__icon_twitter {
    width: 19px;
    height: 15px;
  }
}
.socials__icon_facebook {
  width: 8px;
  height: 18px;
}
@media screen and (min-width: 1200px) {
  .socials__icon_facebook {
    width: 10px;
    height: 21px;
  }
}
.socials__icon_telegram {
  width: 14px;
  height: 11px;
}
@media screen and (min-width: 1200px) {
  .socials__icon_telegram {
    width: 21px;
    height: 17px;
  }
}
.socials__icon_discord {
  width: 18px;
  height: 13px;
}
@media screen and (min-width: 1200px) {
  .socials__icon_discord {
    width: 26px;
    height: 20px;
  }
}
.socials__icon_m {
  width: 13px;
  height: 11px;
}
@media screen and (min-width: 1200px) {
  .socials__icon_m {
    width: 20px;
    height: 16px;
  }
}
.socials__icon_github {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}
.socials__dots {
  padding: 6px 3px;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .socials__dots {
    padding: 12px 3px;
  }
}
.socials__dot {
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background: #FFF;
  margin-bottom: 2px;
}
.socials_footer {
  position: relative;
  padding-right: 15px;
}
@media screen and (min-width: 990px) {
  .socials_footer {
    justify-content: flex-end;
  }
}
.socials_footer .socials__link {
  width: 25px;
  height: 25px;
}
@media screen and (min-width: 1200px) {
  .socials_footer .socials__link {
    width: 38px;
    height: 38px;
  }
}
@media screen and (min-width: 990px) {
  .socials_footer .socials__dots {
    position: absolute;
    top: 0;
    right: 3px;
  }
}

.pull__icon {
  width: 22px;
  height: 20px;
  position: relative;
}
.pull__icon div {
  border-radius: 4px;
  width: 100%;
  height: 3px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
}
.pull__icon div:nth-child(2) {
  top: 9px;
}
.pull__icon div:last-child {
  top: 18px;
}
.pull.active .pull__icon div {
  transform: translateY(9px) rotate(-45deg);
}
.pull.active .pull__icon div:nth-child(2) {
  display: none;
}
.pull.active .pull__icon div:last-child {
  transform: translateY(-9px) rotate(45deg);
}

.challenge {
  background: rgba(32, 41, 58, 0.77);
  backdrop-filter: blur(71.9002px);
  border-radius: 38px;
  height: 100%;
  padding: 5px 25px 28px;
}
@media screen and (min-width: 1200px) {
  .challenge {
    padding: 5px 30px 28px;
  }
}
.challenge__img-wrapper {
  position: relative;
  margin: 0 auto;
  width: 80%;
  padding-top: 80%;
  animation: bounce 3s linear infinite;
}
@media screen and (min-width: 480px) {
  .challenge__img-wrapper {
    padding-top: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 600px) {
  .challenge__img-wrapper {
    padding-top: 100%;
    width: auto;
  }
}
.challenge__img-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 65%;
  height: 65%;
  background: rgba(85, 33, 206, 0.62);
  filter: blur(65px);
}
.challenge__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80%;
  max-width: none;
}
.challenge__content {
  text-align: center;
}
.challenge__title {
  color: #FFF;
  font: 700 17px/1.15 "Work Sans", sans-serif;
  margin-bottom: 15px;
}
@media screen and (min-width: 1200px) {
  .challenge__title {
    font-size: 28px;
  }
}
.challenge__text {
  margin-bottom: 20px;
}
.challenge__text p {
  color: #FFF;
  margin-bottom: 10px;
  font: 400 12px/1.5 "Work Sans", sans-serif;
}
@media screen and (min-width: 1200px) {
  .challenge__text p {
    font-size: 16px;
  }
}

.product {
  border-radius: 28px;
  background: #1A1B22;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(71px);
  padding: 26px 14px;
  text-align: center;
  min-height: 315px;
  height: 100%;
}
@media screen and (min-width: 990px) {
  .product {
    padding: 40px 20px;
  }
}
.product_violet {
  background: linear-gradient(180deg, #15032E 0%, #7F00FF 100%);
}
.product_pink {
  background: linear-gradient(180deg, #29052D 0%, #E1169C 100%);
}
.product__chip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  padding: 6px 12px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.34);
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0) 100%);
}
@media screen and (min-width: 990px) {
  .product__chip {
    padding: 11px 20px;
    font-size: 20px;
  }
}
.product_text {
  background: #090A0E;
  text-align: left;
  border: 5px solid #FFF;
  display: flex;
  align-items: center;
}
.product__img-wrapper {
  margin-bottom: 15px;
  position: relative;
  height: 230px;
  animation: bounce 3s linear infinite;
}
@media screen and (min-width: 990px) {
  .product__img-wrapper {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
  .product__img-wrapper {
    height: 300px;
  }
}
.product__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
}
.product__title {
  color: #FFF;
  font: 700 20px/1.2 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 1200px) {
  .product__title {
    font-size: 24px;
  }
}
.product__text p {
  color: #FFF;
  font: 500 14px/1.17 "Work Sans", sans-serif;
  margin-bottom: 15px;
}
@media screen and (min-width: 1200px) {
  .product__text p {
    font-size: 17px;
  }
}

.ecosystem-item {
  padding: 20px 20px 10px;
  height: 100%;
  border-radius: 22px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 600px) {
  .ecosystem-item {
    display: block;
    padding: 10px 20px 20px;
  }
}
.ecosystem-item_green {
  background: linear-gradient(270.58deg, #2ADE67 0.44%, #033528 98.23%);
}
@media screen and (min-width: 600px) {
  .ecosystem-item_green {
    background: linear-gradient(180deg, #033428 0%, #00CB6E 100%);
  }
}
.ecosystem-item_blue {
  background: linear-gradient(270deg, #275BDF 0%, #0A0A46 100%);
}
@media screen and (min-width: 600px) {
  .ecosystem-item_blue {
    background: linear-gradient(180deg, #0A0944 0%, #275BDF 100%);
  }
}
.ecosystem-item_violet {
  background: linear-gradient(270.45deg, #7900F3 0.38%, #1A0239 99.68%);
}
@media screen and (min-width: 600px) {
  .ecosystem-item_violet {
    background: linear-gradient(180deg, #15032E 0%, #7F00FF 100%);
  }
}
.ecosystem-item__img-wrapper {
  position: relative;
  width: 58px;
  margin-bottom: 10px;
  flex: 0 0 auto;
  margin-right: 20px;
  animation: bounce 3s linear infinite;
}
@media screen and (min-width: 600px) {
  .ecosystem-item__img-wrapper {
    height: 122px;
    width: auto;
    margin-right: 0;
    margin-bottom: 17px;
  }
}
.ecosystem-item__img {
  max-width: none;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .ecosystem-item__img {
    width: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.ecosystem-item__text {
  color: #FFF;
  font: 700 13px/1.31 "Work Sans", sans-serif;
  margin: 0 auto 10px;
}
@media screen and (min-width: 600px) {
  .ecosystem-item__text {
    text-align: center;
    max-width: 305px;
    font-size: 15px;
    margin: 0 auto 20px;
  }
}
@media screen and (min-width: 768px) {
  .ecosystem-item__text {
    font-size: 18px;
  }
}

.tabs-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}
.tabs-menu__item {
  display: block;
  width: 100%;
  padding: 0 4px;
  margin-bottom: 8px;
}
@media screen and (min-width: 480px) {
  .tabs-menu__item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .tabs-menu__item {
    width: 25%;
  }
}
.tabs-menu__link {
  display: block;
  text-decoration: none;
  background-color: #1E1F2A;
  border-radius: 12px;
  height: 100%;
  text-align: center;
  padding: 12px 6px 6px;
  transition: background-color 0.3s ease;
  min-height: 57px;
}
@media screen and (min-width: 990px) {
  .tabs-menu__link {
    padding: 24px 12px 12px;
  }
}
.tabs-menu__link.active {
  background-color: #1D59F6;
}
.tabs-menu__title {
  color: #FFF;
  font: 700 14px/1.4 "Work Sans", sans-serif;
}
.tabs-menu__subtitle {
  color: #7A7B81;
  font: 700 14px/1.4 "Work Sans", sans-serif;
}
.tabs-menu__run {
  display: flex;
  justify-content: center;
}
.tabs-menu__run-icon {
  width: 12px;
  height: 16px;
  margin-right: 5px;
}
.tabs-menu__icon {
  display: inline-block;
  width: 20px;
  height: 19px;
}

.sale {
  position: relative;
  background: linear-gradient(90deg, #b518ff 0%, #587bff 50%, #27e55c 100%);
  border-radius: 25px;
  padding: 1px;
}
.sale:before, .sale:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 38px;
  width: 80%;
  height: 90%;
  border-radius: 50%;
  background: #8123F9;
  filter: blur(85px);
  z-index: -1;
}
.sale:after {
  height: 80%;
  width: 60%;
  bottom: 20px;
  background: #2FD5B7;
  filter: blur(92px);
  left: auto;
  right: 38px;
}
.sale__wrapper {
  padding: 27px 15px 10px 15px;
  background: #1E1F2A;
  border-radius: 25px;
}
@media screen and (min-width: 480px) {
  .sale__wrapper {
    padding: 27px 20px 10px 20px;
  }
}
@media screen and (min-width: 990px) {
  .sale__wrapper {
    padding: 44px 37px 19px 37px;
  }
}
.sale__content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.sale__left {
  padding: 0 20px;
  width: 100%;
}
@media screen and (min-width: 990px) {
  .sale__left {
    width: 60%;
  }
}
.sale__right {
  padding: 0 20px;
  width: 100%;
}
@media screen and (min-width: 990px) {
  .sale__right {
    width: 40%;
  }
}
.sale__title {
  color: #FFF;
  font: 700 18px/1.1 "Work Sans", sans-serif;
  margin-bottom: 26px;
  text-align: center;
}
@media screen and (min-width: 480px) {
  .sale__title {
    font-size: 22px;
    margin-bottom: 34px;
  }
}
@media screen and (min-width: 990px) {
  .sale__title {
    text-align: left;
  }
}
.sale__info {
  display: flex;
  margin: 0 -4px 10px;
}
@media screen and (min-width: 480px) {
  .sale__info {
    margin: 0 -7px 17px;
  }
}
.sale__info-item {
  padding: 0 4px;
  width: 50%;
}
@media screen and (min-width: 480px) {
  .sale__info-item {
    padding: 0 7px;
  }
}
.sale__info-count {
  background: #1D59F6;
  border-radius: 12px;
  text-align: center;
  color: #FFF;
  font: 400 16px/1.2 "Work Sans", sans-serif;
  padding: 7px 15px;
  margin-bottom: 10px;
}
@media screen and (min-width: 480px) {
  .sale__info-count {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 600px) {
  .sale__info-count {
    font-size: 20px;
  }
}
.sale__info-description {
  color: #FFF;
  text-align: center;
  font: 500 12px/1.2 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 480px) {
  .sale__info-description {
    font-size: 14px;
  }
}
@media screen and (min-width: 600px) {
  .sale__info-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.sale__progress {
  padding-top: 20px;
  margin-bottom: 36px;
}
@media screen and (min-width: 600px) {
  .sale__progress {
    margin-bottom: 46px;
  }
}
.sale__progress-info {
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
}
.sale__progress-item {
  padding: 0 5px;
  color: #FFF;
  font: 400 12px/1.2 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .sale__progress-item {
    font-size: 14px;
  }
}
.sale__line {
  position: relative;
  width: 100%;
  height: 13px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 11px;
}
@media screen and (min-width: 480px) {
  .sale__line {
    height: 17px;
  }
}
.sale__line-progress {
  position: absolute;
  top: 2px;
  left: 0;
  height: 9px;
  background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
  border-radius: 6px;
}
@media screen and (min-width: 480px) {
  .sale__line-progress {
    top: 4px;
  }
}
.sale__line-progress:before {
  content: "";
  display: block;
  position: absolute;
  right: -2px;
  top: -6px;
  width: 2px;
  height: 21px;
  background: #FFF;
}
.sale__line-count {
  position: absolute;
  top: -20px;
  color: #FFF;
  font: 400 12px/1.2 "Work Sans", sans-serif;
  transform: translateX(-50%);
}
.sale__footer-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px 6px;
}
@media screen and (min-width: 600px) {
  .sale__footer-list {
    margin: 0 -4px 13px;
  }
}
.sale__footer-item {
  padding: 0 4px;
  margin-bottom: 8px;
}
.sale__bsc {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 13px;
  padding: 7px 12px;
}
.sale__bsc-content {
  display: flex;
  align-items: center;
}
.sale__bsc-img {
  flex: 0 0 auto;
  width: 15px;
  margin-right: 5px;
}
@media screen and (min-width: 480px) {
  .sale__bsc-img {
    width: 19px;
  }
}
.sale__bsc-copy {
  padding: 0 3px;
  flex: 0 0 auto;
  width: 21px;
  height: 16px;
  fill: #FFF;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
@media screen and (min-width: 480px) {
  .sale__bsc-copy {
    width: 24px;
    height: 19px;
  }
}
@media screen and (min-width: 1200px) {
  .sale__bsc-copy:hover, .sale__bsc-copy:active {
    opacity: 1;
  }
}
.sale__bsc-label {
  position: relative;
  display: flex;
  align-items: center;
}
.sale__bsc-span {
  display: inline-block;
  color: #8E91A5;
  font: 600 10px/1.2 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .sale__bsc-span {
    font-size: 13px;
  }
}
.sale__bsc-address, .sale__bsc-input {
  display: block;
  background: none;
  outline: none;
  border: none;
  color: #FFF;
  font: 600 10px/1.2 "Work Sans", sans-serif;
  width: 100%;
  padding: 1px 3px;
}
@media screen and (min-width: 480px) {
  .sale__bsc-address, .sale__bsc-input {
    font-size: 13px;
    width: 80px;
  }
}
.sale__bsc-input {
  right: 0;
  position: absolute;
  z-index: -2;
}
.sale__coinmarketcap {
  display: block;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 13px;
  padding: 7px 12px;
}
@media screen and (min-width: 1200px) {
  .sale__coinmarketcap:hover, .sale__coinmarketcap:active {
    text-decoration: underline;
  }
}
.sale__coinmarketcap-content {
  display: flex;
  align-items: center;
}
.sale__coinmarketcap-img {
  width: 15px;
  margin-right: 5px;
}
@media screen and (min-width: 480px) {
  .sale__coinmarketcap-img {
    width: 20px;
  }
}
.sale__coinmarketcap-text {
  color: #FFF;
  font: 600 10px/1.2 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .sale__coinmarketcap-text {
    font-size: 13px;
  }
}
.sale__vesting {
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 13px;
  padding: 10px 14px;
  color: #FFF;
  font: 400 10px/1.2 "Work Sans", sans-serif;
  margin-bottom: 20px;
}
@media screen and (min-width: 480px) {
  .sale__vesting {
    font-size: 13px;
  }
}
.sale__form {
  height: 100%;
}
.sale__exchange {
  position: relative;
  margin-bottom: 10px;
}
.sale__exchange-info {
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
}
.sale__exchange-item {
  padding: 0 5px;
  margin-bottom: 10px;
}
.sale__exchange-text {
  color: #FFF;
  font: 500 12px/1.2 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .sale__exchange-text {
    font-size: 13px;
  }
}
.sale__exchange-title {
  color: #8E91A5;
}
.sale__field {
  margin-bottom: 17px;
  background: #0F1017;
  border-radius: 12px;
  padding: 20px 18px;
}
.sale__field-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -5px;
}
.sale__field-select {
  padding: 0 5px;
  flex: 0 0 auto;
}
.sale__input-wrapper {
  padding: 0 5px;
}
.sale__input {
  display: block;
  border: 0;
  text-align: right;
  background: none;
  color: #FFF;
  font: 700 18px/1.2 "Work Sans", sans-serif;
  padding: 0;
  outline: none;
  width: 100%;
}
.sale__arrow-wrapper {
  position: absolute;
  top: 47px;
  left: 50%;
  transform: translateX(-50%);
  background: #1E1F2A;
  border-radius: 50%;
  padding: 11px;
  width: 54px;
  height: 54px;
}
.sale__arrow {
  position: relative;
  width: 32px;
  height: 32px;
  background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
  border-radius: 50%;
}
.sale__arrow:before {
  content: "";
  display: block;
  width: 2px;
  height: 15px;
  background: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sale__arrow:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.sale__rax {
  display: flex;
  align-items: center;
  color: #FFF;
  font: 700 18px/1 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .sale__rax {
    font-size: 20px;
  }
}
.sale__rax-img {
  flex: 0 0 auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 7px;
}
.sale__btn {
  display: block;
  width: 100%;
  text-align: center;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
  border-radius: 15px;
  color: #FFF;
  font: 700 16px/1.2 "Work Sans", sans-serif;
  padding: 15px;
  margin-bottom: 14px;
}
.sale__btn-description {
  color: #FFF;
  font: 400 10px/1.5 "Work Sans", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 480px) {
  .sale__btn-description {
    font-size: 11px;
  }
}
.sale__wallets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -7px;
}
@media screen and (min-width: 480px) {
  .sale__wallets {
    margin: 0 -10px;
  }
}
.sale__wallets-item {
  padding: 0 7px;
  margin-bottom: 20px;
}
@media screen and (min-width: 480px) {
  .sale__wallets-item {
    padding: 0 10px;
  }
}
.sale__timer {
  margin-bottom: 34px;
}

.timer {
  background: #0F1017;
  border-radius: 15px;
  padding: 27px;
}
.timer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -25px;
}
.timer__time {
  position: relative;
  padding: 0 25px;
}
.timer__time:before {
  content: ":";
  display: block;
  position: absolute;
  left: -5px;
  top: 0;
  color: #414248;
  font: 500 50px/1.2 "Work Sans", sans-serif;
}
.timer__time:first-child:before {
  display: none;
}
.timer__count {
  color: #FFF;
  font: 500 50px/1.2 "Work Sans", sans-serif;
  text-align: center;
}
.timer__description {
  color: #FFF;
  font: 500 14px/1.2 "Work Sans", sans-serif;
  text-align: center;
}

.bg-gray {
  background: #14151B;
  margin-bottom: 60px;
}

.table {
  background: #1E1F24;
  border-radius: 28px;
  padding: 25px 0 0;
}
@media screen and (min-width: 990px) {
  .table {
    padding: 12px;
  }
}
.table__table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  color: #FFF;
  font: 300 19px/1.4 "Work Sans", sans-serif;
}
@media screen and (min-width: 990px) {
  .table__table {
    font-size: 15px;
    font-weight: 500;
  }
}
.table__head {
  display: none;
}
@media screen and (min-width: 990px) {
  .table__head {
    display: table-header-group;
  }
}
.table__head .table__cell {
  text-align: left;
  padding: 15px 25px 30px;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
}
.table__body .table__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media screen and (min-width: 990px) {
  .table__body .table__row {
    display: table-row;
    margin-bottom: 0;
  }
}
.table__body .table__cell:first-child {
  order: 1;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 990px) {
  .table__body .table__cell:first-child {
    order: 0;
    width: auto;
  }
}
.table__body .table__cell:last-child {
  width: 100%;
  color: #717584;
  order: 2;
  font-weight: 400;
}
@media screen and (min-width: 990px) {
  .table__body .table__cell:last-child {
    order: 0;
    color: #FFF;
    width: auto;
  }
}
.table__body .table__cell {
  padding: 2px 25px;
}
@media screen and (min-width: 990px) {
  .table__body .table__cell {
    background: #0F1017;
    padding: 9px 25px;
  }
}
.table__footer .table__row {
  display: flex;
  padding: 0 20px;
}
@media screen and (min-width: 990px) {
  .table__footer .table__row {
    display: table-row;
    padding: 0;
  }
}
.table__footer .table__cell {
  padding: 5px 5px 25px;
  font-weight: 700;
  font-size: 17px;
}
@media screen and (min-width: 990px) {
  .table__footer .table__cell {
    padding: 25px;
  }
}
.table__body .table__cell:first-child {
  font-weight: 700;
}
.table__body .table__cell:last-child {
  font-size: 11px;
}
@media screen and (min-width: 990px) {
  .table__body .table__cell:last-child {
    font-size: 14px;
  }
}
.table__body .table__row:first-child .table__cell {
  padding: 2px 25px;
}
@media screen and (min-width: 990px) {
  .table__body .table__row:first-child .table__cell {
    padding: 25px 25px 9px;
  }
}
.table__body .table__row:last-child .table__cell {
  padding: 2px 25px;
}
@media screen and (min-width: 990px) {
  .table__body .table__row:last-child .table__cell {
    padding: 9px 25px 25px;
  }
}
.table__body .table__row:first-child .table__cell:first-child {
  border-radius: 24px 0 0 0;
}
.table__body .table__row:first-child .table__cell:last-child {
  border-radius: 0 24px 0 0;
}
.table__body .table__row:last-child .table__cell:first-child {
  border-radius: 0 0 0 24px;
}
.table__body .table__row:last-child .table__cell:last-child {
  border-radius: 0 0 24px 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease;
  z-index: 1111;
  background: #090A0EDE;
  backdrop-filter: blur(24px);
}
.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
@media screen and (min-width: 768px) {
  .header__wrapper {
    padding-top: 20px;
  }
}
.header__logo {
  flex: 0 0 auto;
  width: 94px;
  margin-bottom: 27px;
  margin-right: 25px;
}
@media screen and (min-width: 768px) {
  .header__logo {
    width: 127px;
  }
}
.header__block {
  display: flex;
  align-items: center;
  margin: 0 -11px 10px;
}
@media screen and (min-width: 990px) {
  .header__block {
    margin: 0 -22px 16px;
  }
}
.header__links-wrapper {
  padding: 0 22px;
  display: none;
}
@media screen and (min-width: 990px) {
  .header__links-wrapper {
    display: block;
  }
}
.header__socials-wrapper {
  display: none;
}
@media screen and (min-width: 990px) {
  .header__socials-wrapper {
    padding: 0 22px;
    display: block;
  }
}
.header__socials-wrapper-mobile {
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
@media screen and (min-width: 990px) {
  .header__socials-wrapper-mobile {
    display: none;
  }
}
.header__app-links {
  display: flex;
  align-items: center;
  margin: 0;
}
.header__app-links-item {
  margin-bottom: 10px;
}
.header__app-links-item:last-of-type {
  margin-left: 8px;
  animation: shake-animation 1s infinite ease-out alternate;
}
.header__app-links-item:last-of-type:hover {
  animation: unset;
}
.header__links {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -18px;
}
.header__links-item {
  padding: 0 18px;
  margin-bottom: 10px;
}
.header__links-link {
  display: block;
  text-decoration: none;
  color: #FFF;
  font: 700 16px/1.3 "Work Sans", sans-serif;
  transition: color 0.3s ease;
}
.header__links-link:active {
  color: #7F00FF;
}
@media screen and (min-width: 990px) {
  .header__links-link:hover {
    color: #7F00FF;
  }
}
.header__socials {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  align-items: center;
}
@media screen and (min-width: 990px) {
  .header__socials {
    margin: 0 -15px;
  }
}
.header__socials-item {
  padding: 0 8px;
  margin-bottom: 10px;
}
@media screen and (min-width: 990px) {
  .header__socials-item {
    padding: 0 15px;
  }
}
.header__socials-link {
  display: block;
  text-decoration: none;
}
.header__socials-link_whitelist {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(95.5deg, #587BFF 0.5%, #B518FF 97.9%);
  padding: 7.5px 12.5px;
  border-radius: 52px;
  font: 700 10px/1.3 "Work Sans", sans-serif;
  transition: box-shadow 0.3s ease;
}
@media screen and (min-width: 375px) {
  .header__socials-link_whitelist {
    font-size: 12px;
  }
}
@media screen and (min-width: 480px) {
  .header__socials-link_whitelist {
    width: 136px;
    height: 40px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1200px) {
  .header__socials-link_whitelist {
    width: 136px;
    height: 40px;
  }
  .header__socials-link_whitelist:hover, .header__socials-link_whitelist:active {
    box-shadow: 0 7px 5px RGBA(181, 24, 255, 0.5);
  }
}
.header__socials-icon {
  fill: #C8D0E6;
  opacity: 0.3;
  transition: opacity 0.3s ease;
}
.header__socials-icon:active {
  opacity: 1;
}
@media screen and (min-width: 990px) {
  .header__socials-icon:hover {
    opacity: 1;
  }
}
.header__pull {
  padding: 0 11px;
  margin-bottom: 10px;
}
@media screen and (min-width: 990px) {
  .header__pull {
    display: none;
  }
}
.header__menu {
  transform: scaleY(0);
  transform-origin: 0 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.header__menu-list {
  margin: 0;
}
.header__menu-item {
  text-align: center;
  margin-bottom: 10px;
}
.header__menu-link {
  text-decoration: none;
  display: inline-block;
  padding: 5px;
  color: #FFF;
  font: 700 16px/1.3 "Work Sans", sans-serif;
}
.header.active {
  background: #08161A;
  backdrop-filter: blur(33px);
  border-radius: 0px 0px 20px 20px;
  padding-bottom: 30px;
}
.header.active .header__wrapper {
  padding-bottom: 0;
}
.header.active .header__menu {
  position: relative;
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

@keyframes shake-animation {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
.video {
  margin-top: 75px;
}
@media screen and (min-width: 990px) {
  .video {
    margin-top: 86px;
  }
}
.video__wrapper {
  position: relative;
  padding-top: 25px;
  padding-bottom: 1px;
}
@media screen and (min-width: 990px) {
  .video__wrapper {
    padding-top: 35px;
    padding-bottom: 60px;
  }
}
.video__coins {
  display: none;
  position: absolute;
  top: 0;
  width: 350px;
}
@media screen and (min-width: 1200px) {
  .video__coins {
    display: block;
  }
}
@media screen and (min-width: 1360px) {
  .video__coins {
    width: 400px;
    top: -20px;
  }
}
.video__coins_left {
  left: -165px;
}
.video__coins_right {
  right: -165px;
}
.video__coins-img {
  max-width: none;
  width: 100%;
  animation: bounce 3s linear infinite;
}
.video__title {
  font-family: "Work Sans", sans-serif;
  font-size: 28px;
  text-align: center;
  z-index: 3;
  margin-bottom: 0;
}
@media screen and (min-width: 375px) {
  .video__title {
    font-size: 32px;
  }
}
@media screen and (min-width: 480px) {
  .video__title {
    font-size: 68px;
  }
}
@media screen and (min-width: 1200px) {
  .video__title {
    font-size: 102px;
    font-weight: 200;
    line-height: 120%;
  }
}
.video__title-linear {
  background: linear-gradient(180deg, #FFF 66.98%, #2A0B52 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin: 0 auto 20px;
  width: fit-content;
  font-weight: 700;
}
@media screen and (min-width: 1200px) {
  .video__title-linear {
    margin: 0 auto 40px;
    font-weight: 700;
  }
}
.video__subtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: #67646B;
  text-align: center;
  z-index: 3;
  margin-bottom: 18px;
}
@media screen and (min-width: 375px) {
  .video__subtitle {
    font-size: 18px;
  }
}
@media screen and (min-width: 480px) {
  .video__subtitle {
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .video__subtitle {
    font-size: 27px;
    margin-bottom: 40px;
  }
}
.video__launch-link {
  display: flex;
  width: 224px;
  height: 54px;
  padding: 7.5px 12.5px;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;
  border-radius: 72px;
  background: linear-gradient(96deg, #587BFF 0.5%, #B518FF 97.9%);
  transition: box-shadow 0.3s ease;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
  line-height: 138%;
  text-decoration: none;
}
@media screen and (min-width: 480px) {
  .video__launch-link {
    margin: 0 auto 80px;
    width: 276px;
    height: 76px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1200px) {
  .video__launch-link {
    font-size: 18px;
  }
  .video__launch-link:hover, .video__launch-link:active {
    box-shadow: 0 7px 5px RGBA(181, 24, 255, 0.5);
  }
}
.video__browser {
  position: relative;
  max-width: 1166px;
  margin: 0 auto 34px;
  padding: 0 10px;
}
@media screen and (min-width: 1360px) {
  .video__browser {
    padding: 0;
  }
}
.video__browser:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 80%;
  border-radius: 50%;
  background: #8123F9;
  filter: blur(60px);
}
@media screen and (min-width: 480px) {
  .video__browser:before {
    filter: blur(110px);
    top: 10%;
    height: 60%;
  }
}
.video__browser:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 50px;
  width: 60%;
  height: 80%;
  border-radius: 50%;
  background: #2FD5B7;
  filter: blur(60px);
}
@media screen and (min-width: 480px) {
  .video__browser:after {
    filter: blur(110px);
    top: -2%;
    height: 90%;
  }
}
.video__spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video__spinner-wrapper.disabled {
  display: none;
}
.video__spinner {
  height: 60px;
  width: 60px;
  border: 3px solid transparent;
  border-top-color: #A04668;
  margin: -30px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.video__spinner:before, .video__spinner:after {
  content: "";
  display: block;
  position: absolute;
  border: 3px solid transparent;
  border-radius: 50%;
}
.video__spinner:before {
  border-top-color: #254E70;
  top: -12px;
  left: -12px;
  right: -12px;
  bottom: -12px;
  animation: spin 3s linear infinite;
}
.video__spinner:after {
  border-top-color: #FFFBFE;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  animation: spin 4s linear infinite;
}
.video__video {
  position: relative;
  z-index: 1;
  border-radius: 9px;
  width: 100%;
  height: auto;
}
.video__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -8px;
}
.video__links-item {
  padding: 0 8px;
  margin-bottom: 16px;
  width: 100%;
}
@media screen and (min-width: 375px) {
  .video__links-item {
    width: 50%;
  }
}
@media screen and (min-width: 480px) {
  .video__links-item {
    width: auto;
  }
}
.video__links-link {
  display: block;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font: 700 14px/1.3 "Work Sans", sans-serif;
  padding: 16px 23px 14px;
  background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
  border-radius: 15px;
  transition: box-shadow 0.3s ease;
}
@media screen and (min-width: 480px) {
  .video__links-link {
    min-width: 357px;
    font-size: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .video__links-link:hover, .video__links-link:active {
    box-shadow: 0 7px 5px RGBA(88, 123, 255, 0.5);
  }
}

.title {
  font: 300 25px/1.2 "Work Sans", sans-serif;
  text-align: center;
  max-width: 813px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 600px) {
  .title {
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) {
  .title {
    font-size: 40px;
  }
}
@media screen and (min-width: 990px) {
  .title {
    font-size: 55px;
  }
}

.manifest {
  position: relative;
  padding-top: 30px;
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .manifest {
    padding-top: 60px;
    padding-bottom: 88px;
  }
}
@media screen and (min-width: 990px) {
  .manifest {
    padding-bottom: 150px;
  }
}
.manifest__rax {
  position: absolute;
  width: 180%;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  max-width: none;
  z-index: -1;
}
@media screen and (min-width: 990px) {
  .manifest__rax {
    width: 2152px;
    bottom: 0;
    top: auto;
  }
}
.manifest__head {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .manifest__head {
    margin-bottom: 50px;
  }
}
.manifest__title {
  font-weight: 700;
  margin-bottom: 5px;
  max-width: unset;
}
@media screen and (min-width: 768px) {
  .manifest__title {
    margin-bottom: 15px;
  }
}
.manifest__description {
  text-align: center;
  font: 400 13px/1 "Work Sans", sans-serif;
  color: #717584;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .manifest__description {
    font-size: 20px;
  }
}
.manifest__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -9px;
}
@media screen and (min-width: 1200px) {
  .manifest__list {
    margin: 0 -17px;
  }
}
.manifest__item {
  padding: 0 9px;
  width: 100%;
  margin-bottom: 18px;
}
@media screen and (min-width: 600px) {
  .manifest__item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .manifest__item {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 1200px) {
  .manifest__item {
    padding: 0 17px;
    margin-bottom: 34px;
  }
}
.manifest__block {
  max-width: 979px;
  margin: 0 auto;
  padding: 30px 24px 15px;
  background: rgba(0, 13, 39, 0.65);
  backdrop-filter: blur(60px);
  border-radius: 46px;
}
@media screen and (min-width: 768px) {
  .manifest__block {
    padding: 40px 48px 30px;
  }
}
.manifest__block-title {
  text-align: center;
  color: #FFF;
  font: 200 25px/1 "Work Sans", sans-serif;
  margin-bottom: 18px;
}
@media screen and (min-width: 480px) {
  .manifest__block-title {
    font-size: 40px;
  }
}
@media screen and (min-width: 990px) {
  .manifest__block-title {
    font-size: 55px;
    margin-bottom: 30px;
  }
}
.manifest__text {
  margin-bottom: 35px;
}
.manifest__text p {
  color: #FFF;
  font: 400 13px/1.5 "Work Sans", sans-serif;
  margin-bottom: 21px;
}
@media screen and (min-width: 768px) {
  .manifest__text p {
    font-size: 17px;
    margin-bottom: 27px;
  }
}
.manifest__footer {
  margin: 0 -13px;
}
@media screen and (min-width: 768px) {
  .manifest__footer {
    display: flex;
  }
}
.manifest__link-wrapper {
  padding: 0 13px;
  flex: 0 0 auto;
  margin-bottom: 20px;
}
.manifest__link {
  display: block;
  text-decoration: none;
  color: #FFF;
  font: 700 14px/1.3 "Work Sans", sans-serif;
  text-align: center;
  background: linear-gradient(91.81deg, #7520FF 0.6%, #AF1FFB 102.02%);
  border-radius: 15px;
  padding: 15px 26px;
}
@media screen and (min-width: 768px) {
  .manifest__link {
    font-size: 16px;
  }
}
.manifest__followers {
  padding: 0 13px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.manifest__followers-list {
  margin: 0 -3px;
  display: flex;
  position: relative;
  width: 80%;
}
@media screen and (min-width: 768px) {
  .manifest__followers-list {
    max-width: 297px;
  }
}
@media screen and (min-width: 990px) {
  .manifest__followers-list {
    max-width: 550px;
  }
}
.manifest__followers-list:before, .manifest__followers-list:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -3px;
  width: 31px;
  height: 31px;
  background: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, #040C1B 89.06%, rgba(196, 196, 196, 0) 100%);
  z-index: 1;
  opacity: 0.9;
}
@media screen and (min-width: 990px) {
  .manifest__followers-list:before, .manifest__followers-list:after {
    border-radius: 50%;
    left: 0px;
    width: 38px;
    height: 38px;
    background: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, #0A5456 89.06%, rgba(196, 196, 196, 0) 100%);
  }
}
.manifest__followers-list:after {
  left: auto;
  right: -3px;
  transform: rotate(180deg);
}
@media screen and (min-width: 990px) {
  .manifest__followers-list:after {
    background: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, #081F2E 89.06%, rgba(196, 196, 196, 0) 100%);
    left: auto;
    right: 1px;
  }
}
.manifest__followers-item {
  margin: 0 3px;
  position: relative;
  flex: 0 0 auto;
}
.manifest__followers-img {
  max-width: none;
  width: 31px;
  height: 31px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (min-width: 990px) {
  .manifest__followers-img {
    width: 38px;
    height: 38px;
  }
}
.manifest__count-block {
  text-align: center;
  color: #FFF;
  margin-left: 20px;
}
@media screen and (min-width: 990px) {
  .manifest__count-block {
    margin-left: 44px;
  }
}
.manifest__count {
  font: 700 12px/1 "Work Sans", sans-serif;
}
@media screen and (min-width: 768px) {
  .manifest__count {
    font-size: 16px;
  }
}
.manifest__count-text {
  font: 500 11px/1 "Work Sans", sans-serif;
}
@media screen and (min-width: 768px) {
  .manifest__count-text {
    font-size: 15px;
  }
}

.manifest-v2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 70px;
  padding-top: 70px;
}
@media screen and (min-width: 768px) {
  .manifest-v2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 120px;
    padding-top: 210px;
    gap: 80px;
  }
}
.manifest-v2 > .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .content {
    gap: 30px;
  }
}
.manifest-v2 > .content > div > p {
  margin-bottom: 0;
  color: #AEB0B7;
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .content > div > p {
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
  }
}
.manifest-v2 > .content > h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 0;
  margin-right: 20%;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .content > h2 {
    font-size: 88px;
    line-height: 100%;
    margin-right: unset;
  }
}
.manifest-v2 > .content > h2 > .highlight {
  background: var(--Complement-Strong, linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.manifest-v2 > .problems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .problems {
    gap: 28px;
  }
}
.manifest-v2 > .problems > .problem-item {
  display: flex;
  background: #1A1B22;
  padding: 20px 24px;
  border-radius: 28px;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .problems > .problem-item {
    gap: 24px;
  }
}
.manifest-v2 > .problems > .problem-item > .icon {
  margin: auto 0;
  min-width: 52px !important;
  max-width: 52px !important;
  min-height: 52px !important;
  max-height: 52px !important;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .problems > .problem-item > .icon {
    min-width: 84px !important;
    max-width: 84px !important;
    min-height: 84px !important;
    max-height: 84px !important;
  }
}
.manifest-v2 > .problems > .problem-item > p {
  font-size: 15px;
  line-height: 130%;
  font-weight: 600;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .manifest-v2 > .problems > .problem-item > p {
    font-size: 18px;
    line-height: 120%;
  }
}
.manifest-v2 > .problems > .problem-item > p > span {
  background: var(--Complement-Strong-Up, linear-gradient(116deg, #27E55C 1.87%, #58ff98 44.47%, #18ffc1 85.33%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.architecture__title {
  margin-bottom: 17px;
}
@media screen and (min-width: 990px) {
  .architecture__title {
    margin-bottom: 60px;
  }
}
.architecture__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
}
@media screen and (min-width: 990px) {
  .architecture__wrapper {
    flex-direction: row;
    padding-bottom: 30px;
  }
}
.architecture__img-block {
  flex: 0 0 auto;
  order: -1;
  margin-bottom: -10%;
}
@media screen and (min-width: 990px) {
  .architecture__img-block {
    order: 0;
    margin-left: -16%;
    margin-bottom: 0;
  }
}
.architecture__img-wrapper {
  width: 100%;
}
@media screen and (min-width: 990px) {
  .architecture__img-wrapper {
    width: 840px;
  }
}
.architecture__img {
  max-width: none;
  width: 100%;
}
.architecture__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 990px) {
  .architecture__list {
    display: block;
  }
}
.architecture__list-item {
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 375px) {
  .architecture__list-item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .architecture__list-item {
    width: auto;
    margin-bottom: 33px;
  }
}
.architecture__list-item_blue {
  order: 1;
}
@media screen and (min-width: 990px) {
  .architecture__list-item_blue {
    order: 0;
    margin-bottom: 37px;
  }
}
@media screen and (min-width: 990px) {
  .architecture__list-item_green {
    margin-bottom: 66px;
  }
}
.architecture__list-item_violet {
  order: 2;
}
@media screen and (min-width: 990px) {
  .architecture__list-item_violet {
    order: 0;
  }
}
.architecture__text {
  position: relative;
  font: 400 13px/1.2 "Work Sans", sans-serif;
  color: #FFF;
  padding: 18px 14px;
}
@media screen and (min-width: 600px) {
  .architecture__text {
    margin-left: 60px;
  }
}
@media screen and (min-width: 768px) {
  .architecture__text {
    font-size: 16px;
    padding: 25px 20px;
  }
}
@media screen and (min-width: 990px) {
  .architecture__text {
    margin-left: 0;
  }
}
.architecture__text:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 19px;
  background: url("../images/content/border_1.svg") top left/cover no-repeat;
}
@media screen and (min-width: 768px) {
  .architecture__text:before {
    width: 26px;
  }
}
.architecture__list-item_blue .architecture__text:before {
  background: url("../images/content/border_2.svg") top left/cover no-repeat;
}
.architecture__list-item_green .architecture__text:before {
  background: url("../images/content/border_3.svg") top left/cover no-repeat;
}
.architecture__list-item_violet .architecture__text:before {
  background: url("../images/content/border_4.svg") top left/cover no-repeat;
}

.full {
  text-decoration: none;
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 15px;
  padding: 5px 35px 5px 20px;
}
.full__text {
  color: #FFF;
  font: 700 14px/1.5 "Work Sans", sans-serif;
}
.full__icon {
  fill: #FFF;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.scheme__wrapper {
  padding-top: 10px;
  padding-bottom: 95px;
}
@media screen and (min-width: 990px) {
  .scheme__wrapper {
    padding-top: 40px;
    padding-bottom: 170px;
  }
}
.scheme__title {
  font-weight: 700;
  margin-bottom: 21px;
  font-size: 35px;
}
@media screen and (min-width: 600px) {
  .scheme__title {
    font-size: 35px;
  }
}
@media screen and (min-width: 990px) {
  .scheme__title {
    margin-bottom: 27px;
  }
}
.scheme__text {
  position: relative;
  text-align: center;
  max-width: 1137px;
  margin: 0 auto 20px;
}
.scheme__text p {
  color: #717584;
  font: 400 13px/1.3 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .scheme__text p {
    font-size: 16px;
  }
}
.scheme__full {
  text-align: center;
  margin-bottom: 25px;
}
@media screen and (min-width: 990px) {
  .scheme__full {
    display: none;
  }
}
.scheme__img-wrapper {
  position: relative;
  margin: -10% -12% -8% -15%;
}
.scheme__img {
  max-width: none;
  width: 100%;
}
.scheme__trade-fee {
  display: none;
  margin: 0;
  text-align: center;
  font-size: 10px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 1200px) {
  .scheme__trade-fee {
    bottom: 120px;
  }
}
@media screen and (min-width: 990px) {
  .scheme__trade-fee {
    left: 40%;
    bottom: 90px;
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
  .scheme__trade-fee {
    bottom: 60px;
  }
}
@media screen and (min-width: 599px) {
  .scheme__trade-fee {
    display: block;
    position: absolute;
    left: 40%;
    bottom: 45px;
  }
}
.scheme__trade-fee-mob {
  display: block;
  text-align: center;
  font-size: 10px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 10px;
}
@media screen and (min-width: 599px) {
  .scheme__trade-fee-mob {
    display: none;
  }
}
.scheme__description {
  color: #717584;
  font: 400 13px/1.3 "Work Sans", sans-serif;
  margin-bottom: 15px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .scheme__description {
    font-size: 16px;
  }
}

.products__cover {
  padding-top: 15px;
  padding-bottom: 55px;
}
.products__head {
  margin-bottom: 32px;
}
@media screen and (min-width: 990px) {
  .products__head {
    margin-bottom: 40px;
  }
}
.products__title {
  max-width: none;
  margin-bottom: 0;
}
@media screen and (min-width: 990px) {
  .products__title {
    text-align: center;
  }
}
.products__description {
  font-size: 16px;
  color: #717584;
}
@media screen and (min-width: 990px) {
  .products__description {
    font-size: 24px;
  }
}
.products__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 15px;
}
@media screen and (min-width: 990px) {
  .products__list {
    margin: 0 -16px 50px;
  }
}
.products__item {
  margin-bottom: 21px;
  padding: 0 10px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .products__item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .products__item {
    width: 33.3333333333%;
    margin-bottom: 32px;
    padding: 0 16px;
  }
}

.utility__wrapper {
  padding-bottom: 50px;
}
@media screen and (min-width: 990px) {
  .utility__wrapper {
    padding-bottom: 180px;
    padding-top: 40px;
  }
}
.utility__title {
  margin-bottom: 13px;
}
@media screen and (min-width: 990px) {
  .utility__title {
    margin-bottom: 80px;
  }
}
.utility__description {
  text-align: center;
  max-width: 865px;
  margin: 0 auto 20px;
  padding: 0 15px;
  color: #FFF;
  font: 400 13px/1.3 "Work Sans", sans-serif;
}
@media screen and (min-width: 768px) {
  .utility__description {
    font-size: 16px;
    padding: 0;
  }
}
.utility__img-wrapper {
  margin-bottom: 60px;
}
.utility__img {
  max-width: none;
  width: 100%;
}
.utility__full {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 10px;
}
@media screen and (min-width: 990px) {
  .utility__full {
    display: none;
  }
}
.utility__list {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
}
@media screen and (min-width: 990px) {
  .utility__list {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 1200px) {
  .utility__list {
    margin: 0 -22px;
  }
}
.utility__list-item {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 18px;
}
@media screen and (min-width: 375px) {
  .utility__list-item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .utility__list-item {
    width: 25%;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .utility__list-item {
    padding: 0 22px;
  }
}

.cases__wrapper {
  position: relative;
  padding-bottom: 25px;
}
@media screen and (min-width: 990px) {
  .cases__wrapper {
    padding-bottom: 115px;
  }
}
.cases__head {
  margin-bottom: 62px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .cases__head {
    text-align: center;
  }
}
.cases__title {
  font-size: 35px;
}
.cases__description {
  color: #717584;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
@media screen and (min-width: 990px) {
  .cases__description {
    font-size: 22px;
  }
}
.cases__list {
  margin: 0 -12px;
  max-width: 636px;
}
@media screen and (min-width: 768px) {
  .cases__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}
.cases__list-item {
  width: 264px;
  padding: 0 12px;
  margin-bottom: 39px;
}
@media screen and (min-width: 768px) {
  .cases__list-item {
    width: 50%;
    margin-bottom: 24px;
  }
}
.cases__img-1 {
  position: absolute;
  top: 390px;
  right: -20px;
  width: 161px;
  max-width: none;
  transform: rotate(10deg);
}
@media screen and (min-width: 768px) {
  .cases__img-1 {
    width: 264px;
    transform: rotate(-12deg);
    top: 90px;
    left: 0;
    right: auto;
  }
}
.cases__img-2 {
  position: absolute;
  top: 235px;
  right: -20px;
  width: 125px;
  max-width: none;
  transform: rotate(-6deg);
}
@media screen and (min-width: 768px) {
  .cases__img-2 {
    top: 330px;
    left: -105px;
    width: 186px;
    right: auto;
  }
}
.cases__img-3 {
  position: absolute;
  top: -10px;
  right: -80px;
  width: 215px;
  max-width: none;
  transform: rotate(-12deg);
}
@media screen and (min-width: 768px) {
  .cases__img-3 {
    transform: rotate(8deg);
    top: 234px;
    left: 60px;
    right: auto;
    width: 307px;
  }
}
.cases__img-4 {
  position: absolute;
  top: 850px;
  right: -45px;
  width: 250px;
  max-width: none;
  transform: rotate(8deg);
}
@media screen and (min-width: 768px) {
  .cases__img-4 {
    top: 536px;
    left: -45px;
    width: 324px;
    right: auto;
  }
}
.cases__img-5 {
  position: absolute;
  top: 560px;
  right: -75px;
  width: 247px;
  max-width: none;
  transform: rotate(-18deg);
}
@media screen and (min-width: 768px) {
  .cases__img-5 {
    top: 95px;
    right: -75px;
    width: 362px;
    transform: rotate(4deg);
  }
}
.cases__img-6 {
  position: absolute;
  top: 1070px;
  right: -110px;
  width: 200px;
  max-width: none;
  transform: rotate(-20deg);
}
@media screen and (min-width: 600px) {
  .cases__img-6 {
    right: -40px;
  }
}
@media screen and (min-width: 768px) {
  .cases__img-6 {
    top: 351px;
    right: -180px;
    width: 294px;
    transform: rotate(9deg);
  }
}
.cases__img-7 {
  position: absolute;
  top: 740px;
  right: -20px;
  width: 88px;
  max-width: none;
  transform: rotate(-5deg);
}
@media screen and (min-width: 768px) {
  .cases__img-7 {
    top: 352px;
    right: 150px;
    width: 152px;
    transform: rotate(6deg);
  }
}
.cases__img-8 {
  position: absolute;
  top: 1225px;
  right: -63px;
  width: 237px;
  max-width: none;
  transform: rotate(-6deg);
}
@media screen and (min-width: 768px) {
  .cases__img-8 {
    top: 538px;
    right: -63px;
    width: 350px;
  }
}

.extended-apps__wrapper {
  padding-bottom: 5px;
}
@media screen and (min-width: 990px) {
  .extended-apps__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 65px;
  }
}
@media screen and (min-width: 1360px) {
  .extended-apps__wrapper {
    margin-left: -345px;
    padding-bottom: 10px;
  }
}
.extended-apps_collection .extended-apps__wrapper {
  justify-content: space-around;
  padding-top: 35px;
  padding-bottom: 5px;
}
@media screen and (min-width: 1200px) {
  .extended-apps_collection .extended-apps__wrapper {
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 1360px) {
  .extended-apps_collection .extended-apps__wrapper {
    margin-left: 0;
  }
}
.extended-apps__img-block {
  margin-right: -10%;
}
@media screen and (min-width: 990px) {
  .extended-apps__img-block {
    margin-right: 0;
  }
}
@media screen and (min-width: 1360px) {
  .extended-apps__img-block {
    flex: 0 0 auto;
    margin-right: -150px;
  }
}
.extended-apps_collection .extended-apps__img-block {
  margin-right: 0;
}
@media screen and (min-width: 1360px) {
  .extended-apps_collection .extended-apps__img-block {
    margin-right: 0;
  }
}
.extended-apps__img-wrapper {
  max-width: 1256px;
  width: 100%;
  margin-bottom: -5%;
}
@media screen and (min-width: 990px) {
  .extended-apps__img-wrapper {
    margin-bottom: 0;
  }
}
.extended-apps_collection .extended-apps__img-wrapper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 990px) {
  .extended-apps_collection .extended-apps__img-wrapper {
    width: 100%;
  }
}
.extended-apps__img {
  max-width: none;
  width: 100%;
}
@media screen and (min-width: 990px) {
  .extended-apps__text-block {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }
}
.extended-apps__content {
  text-align: center;
  margin: 0 auto 50px;
  max-width: 489px;
}
@media screen and (min-width: 990px) {
  .extended-apps__content {
    text-align: left;
    margin: 0;
  }
}
.extended-apps__title {
  margin-bottom: 20px;
}
@media screen and (min-width: 990px) {
  .extended-apps__title {
    text-align: left;
    margin-bottom: 32px;
  }
}
.extended-apps__text {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .extended-apps__text {
    margin-bottom: 0;
  }
}
.extended-apps__text p {
  color: #717584;
  font: 400 13px/1.56 "Work Sans", sans-serif;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .extended-apps__text p {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 990px) {
  .extended-apps__text p {
    font-size: 16px;
  }
}
.extended-apps__text b {
  color: #FFF;
  font-size: 15px;
}
.extended-apps__links {
  display: flex;
  margin: 0 -8px;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 990px) {
  .extended-apps__links {
    justify-content: flex-start;
  }
}
.extended-apps__link-wrapper {
  padding: 0 8px;
  margin-bottom: 10px;
  width: 100%;
}
@media screen and (min-width: 375px) {
  .extended-apps__link-wrapper {
    width: auto;
  }
}
.extended-apps__link {
  display: block;
  text-decoration: none;
  text-align: center;
  color: #FFF;
  font: 700 14px/1.2 "Work Sans", sans-serif;
  background: rgba(75, 79, 101, 0.32);
  border-radius: 15px;
  padding: 16px 23px;
  min-width: 160px;
}
@media screen and (min-width: 480px) {
  .extended-apps__link {
    min-width: 217px;
  }
}
@media screen and (min-width: 768px) {
  .extended-apps__link {
    font-size: 16px;
  }
}
.extended-apps__link_violet {
  background: linear-gradient(91.81deg, #5874C8 0.6%, #AF1FFB 102.02%);
}

.tokenomics__cover {
  padding-top: 65px;
  padding-bottom: 80px;
}
@media screen and (min-width: 1200px) {
  .tokenomics__cover {
    padding-top: 0;
    padding-bottom: 130px;
  }
}
.tokenomics__wrapper {
  margin: 0 -25px;
}
@media screen and (min-width: 1200px) {
  .tokenomics__wrapper {
    display: flex;
  }
}
.tokenomics__left-block {
  padding: 0 25px;
  margin-bottom: 50px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .tokenomics__left-block {
    display: flex;
  }
}
@media screen and (min-width: 1200px) {
  .tokenomics__left-block {
    width: 33%;
    margin-bottom: 0;
    display: block;
  }
}
.tokenomics__right-block {
  padding: 0 25px;
  margin-top: 15px;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .tokenomics__right-block {
    width: 67%;
  }
}
.tokenomics__schedule-block {
  position: relative;
  width: 100%;
  flex: 0 0 auto;
}
@media screen and (min-width: 768px) {
  .tokenomics__schedule-block {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .tokenomics__schedule-block {
    width: 100%;
  }
}
.tokenomics__title {
  margin-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  .tokenomics__title {
    margin-bottom: 81px;
  }
}
.tokenomics__schedule {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
@media screen and (min-width: 1200px) {
  .tokenomics__schedule {
    width: 87%;
  }
}
.tokenomics__schedule-img {
  width: 100%;
  max-width: none;
}
.tokenomics__info-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px 25px;
}
@media screen and (min-width: 1200px) {
  .tokenomics__info-block {
    flex-direction: column;
  }
}
.tokenomics__rax {
  position: relative;
  border-radius: 19px;
  margin: 0 6px 12px;
  padding: 1px;
  overflow: hidden;
  flex: 1 1 auto;
}
@media screen and (min-width: 480px) {
  .tokenomics__rax {
    flex: 0 1 auto;
    width: calc(50% - 12px);
  }
}
@media screen and (min-width: 600px) {
  .tokenomics__rax {
    flex: 1 1 auto;
    width: auto;
    margin: 0 6px 7px;
  }
}
.tokenomics__rax::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #27e55c 0%, #587bff 50%, #b518ff 100%);
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .tokenomics__rax {
    margin: 0 3px 13px;
  }
}
.tokenomics__rax-content {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 9px 1px 5px 1px;
  background: #090A0E;
  border-radius: 19px;
}
@media screen and (min-width: 990px) {
  .tokenomics__rax-content {
    padding: 13px 11px 9px 9px;
  }
}
.tokenomics__rax-title {
  display: none;
  padding: 0 8px;
  margin-right: auto;
  color: #FFF;
  font: 700 13px/1.4 "Work Sans", sans-serif;
}
@media screen and (min-width: 768px) {
  .tokenomics__rax-title {
    display: block;
  }
}
@media screen and (min-width: 990px) {
  .tokenomics__rax-title {
    font-size: 16px;
  }
}
.tokenomics__rax-icon {
  margin: 0 6px 3px;
  border-radius: 50%;
  background: linear-gradient(122.71deg, #9225FF 11.73%, #00FF66 129.28%);
  width: 39px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .tokenomics__rax-icon {
    margin: 0 8px 3px;
  }
}
.tokenomics__rax-icon-text {
  text-transform: uppercase;
  color: #FFF;
  font: 900 13px/1.4 "Work Sans", sans-serif;
}
.tokenomics__rax-text {
  margin: 0 6px;
}
@media screen and (min-width: 768px) {
  .tokenomics__rax-text {
    margin: 0 8px;
  }
}
.tokenomics__rax-top-text {
  color: #FFF;
  font: 700 20px/1 "Work Sans", sans-serif;
  margin-bottom: 3px;
}
@media screen and (min-width: 990px) {
  .tokenomics__rax-top-text {
    font-size: 27px;
  }
}
.tokenomics__rax-bottom-text {
  color: #FFF;
  font: 700 13px/1 "Work Sans", sans-serif;
  margin-bottom: 3px;
}
@media screen and (min-width: 990px) {
  .tokenomics__rax-bottom-text {
    display: none;
  }
}
.tokenomics__info {
  padding: 0 6px;
  margin-bottom: 12px;
  flex: 1 1 auto;
}
@media screen and (min-width: 480px) {
  .tokenomics__info {
    width: 50%;
    flex: 0 1 auto;
  }
}
@media screen and (min-width: 600px) {
  .tokenomics__info {
    width: auto;
    flex: 1 1 auto;
    padding: 0 3px;
    margin-bottom: 7px;
  }
}
@media screen and (min-width: 1200px) {
  .tokenomics__info {
    margin-bottom: 13px;
  }
}
.tokenomics__info-content {
  padding: 10px 12px 8px 12px;
  background: rgba(31, 33, 45, 0.8);
  border-radius: 15px;
  height: 100%;
}
@media screen and (min-width: 600px) {
  .tokenomics__info-content {
    min-height: 66px;
  }
}
@media screen and (min-width: 990px) {
  .tokenomics__info-content {
    padding: 18px 18px 13px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.tokenomics__info-list {
  display: flex;
  margin: 0 -6px;
}
.tokenomics__info-item {
  position: relative;
  margin: 0 6px 3px;
  color: #FFF;
  font: 300 16px/1.4 "Work Sans", sans-serif;
  padding-left: 25px;
}
@media screen and (min-width: 990px) {
  .tokenomics__info-item {
    font-size: 23px;
  }
}
.tokenomics__info-img-wrapper {
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
}
@media screen and (min-width: 990px) {
  .tokenomics__info-img-wrapper {
    top: 5px;
  }
}
.tokenomics__info__img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tokenomics__info-count {
  color: #FFF;
  font: 300 16px/1 "Work Sans", sans-serif;
  margin-bottom: 8px;
}
@media screen and (min-width: 990px) {
  .tokenomics__info-count {
    font-size: 23px;
  }
}
.tokenomics__info-description {
  color: #FFF;
  font: 600 13px/1 "Work Sans", sans-serif;
  margin-bottom: 8px;
}
@media screen and (min-width: 990px) {
  .tokenomics__info-description {
    order: -1;
    font-size: 16px;
  }
}
.tokenomics__pools {
  display: none;
  font: 700 20px/1.31 "Work Sans", sans-serif;
  text-align: center;
  margin-bottom: 15px;
  background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #587BFF;
}
@media screen and (min-width: 1200px) {
  .tokenomics__pools {
    display: block;
  }
}
.tokenomics__constituents {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}
.tokenomics__constituent {
  width: 100%;
  padding: 0 25px;
  margin-bottom: 30px;
}
@media screen and (min-width: 600px) {
  .tokenomics__constituent {
    width: 50%;
  }
}

.schedule__wrapper {
  padding-top: 60px;
  padding-bottom: 25px;
}
@media screen and (min-width: 990px) {
  .schedule__wrapper {
    padding-bottom: 100px;
    padding-top: 150px;
  }
}
.schedule__title {
  text-align: center;
  color: #FFF;
  font: 700 25px/1.1 "Work Sans", sans-serif;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .schedule__title {
    font-size: 30px;
  }
}
@media screen and (min-width: 990px) {
  .schedule__title {
    font-size: 40px;
  }
}
.schedule__chart {
  position: relative;
  margin: auto;
  width: 100%;
  margin-top: 10px;
  height: 600px;
  margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .schedule__chart {
    margin-top: 40px;
  }
}
.schedule__full {
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (min-width: 990px) {
  .schedule__full {
    display: none;
  }
}

.roadmap__title {
  position: relative;
  text-align: left;
  color: #FFF;
  font: 700 35px/1 "Work Sans", sans-serif;
  margin-bottom: 30px;
  padding-right: 50px;
}
@media screen and (min-width: 768px) {
  .roadmap__title {
    font-size: 40px;
  }
}
@media screen and (min-width: 990px) {
  .roadmap__title {
    font-size: 45px;
    font-weight: 300;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 55px;
  }
}
.roadmap__wrapper {
  position: relative;
  padding-bottom: 23px;
}
@media screen and (min-width: 990px) {
  .roadmap__wrapper {
    padding-bottom: 115px;
  }
}
.roadmap__line {
  position: absolute;
  left: 0;
  top: 88px;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #30FD82 0%, #7F00FF 47.4%, #E1169C 76.04%, #FDCA18 100%);
}
@media screen and (min-width: 990px) {
  .roadmap__line {
    top: 110px;
  }
}
.roadmap__list {
  display: flex;
  overflow-y: auto;
  max-width: 1920px;
  margin: 0 auto;
}
@media screen and (min-width: 990px) {
  .roadmap__list {
    padding: 0 30px;
  }
}
.roadmap__item {
  margin: 0 15px;
  flex: 0 0 auto;
  max-width: 260px;
}
@media screen and (min-width: 990px) {
  .roadmap__item {
    max-width: 320px;
    margin: 0 30px;
    width: 320px !important;
  }
}

.team__wrapper {
  padding-top: 50px;
}
@media screen and (min-width: 990px) {
  .team__wrapper {
    padding-bottom: 60px;
  }
}
.team__title {
  margin-bottom: 30px;
  font-weight: 700;
}
@media screen and (min-width: 990px) {
  .team__title {
    font-weight: 300;
    margin-bottom: 125px;
  }
}
.team__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -17px;
}
.team__item {
  width: 100%;
  margin-bottom: 27px;
  padding: 0 17px;
}
@media screen and (min-width: 768px) {
  .team__item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .team__item {
    width: 33.3333333333%;
  }
}
.team__proscom {
  position: relative;
  border-radius: 26px;
  padding: 3px;
  margin-bottom: 27px;
  overflow: hidden;
}
.team__proscom:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #27e55c 0%, #587bff 50%, #b518ff 100%);
  z-index: -1;
}
.team__proscom-content {
  background: #131418;
  padding: 26px 25px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 26px;
}
@media screen and (min-width: 1200px) {
  .team__proscom-content {
    padding: 46px 52px 31px;
  }
}
.team__proscom-text {
  padding: 0 10px;
  color: #ffffff;
  font: 400 20px/1.1 "Work Sans", sans-serif;
  margin-bottom: 15px;
}
@media screen and (min-width: 1200px) {
  .team__proscom-text {
    font-size: 24px;
  }
}

.companies__wrapper {
  padding-bottom: 57px;
}
.companies__title {
  text-align: center;
  color: #FFF;
  font: 700 25px/1 "Work Sans", sans-serif;
  margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .companies__title {
    font-size: 30px;
  }
}
@media screen and (min-width: 990px) {
  .companies__title {
    font-size: 35px;
  }
}
.companies__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -20px;
  background: #0A0A0A;
  padding: 20px 0 10px;
}
.companies__item {
  padding: 0 20px;
  margin-bottom: 10px;
  width: 33.3333333333%;
}
@media screen and (min-width: 990px) {
  .companies__item {
    width: auto;
  }
}
.companies__img {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  margin: 0 auto;
  max-height: 104px;
  max-width: 210px;
  width: 100%;
}
@media screen and (min-width: 990px) {
  .companies__img {
    margin: 0;
  }
  .companies__img:hover {
    filter: grayscale(0);
  }
}

.news__wrapper {
  padding-top: 18px;
  padding-bottom: 75px;
}
@media screen and (min-width: 990px) {
  .news__wrapper {
    padding-bottom: 150px;
  }
}
.news__title {
  color: rgba(171, 172, 199, 0.12);
  font: 700 45px/1 "Work Sans", sans-serif;
  margin-bottom: -20px;
}
@media screen and (min-width: 375px) {
  .news__title {
    font-size: 55px;
  }
}
@media screen and (min-width: 600px) {
  .news__title {
    font-size: 85px;
  }
}
@media screen and (min-width: 768px) {
  .news__title {
    font-size: 110px;
  }
}
@media screen and (min-width: 990px) {
  .news__title {
    font-size: 145px;
    margin-bottom: -60px;
  }
}
@media screen and (min-width: 1200px) {
  .news__title {
    font-size: 180px;
  }
}
@media screen and (min-width: 1360px) {
  .news__title {
    font-size: 205px;
  }
}
.news__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
  background: #0A0A0A;
  padding: 20px 0 5px;
}
@media screen and (min-width: 990px) {
  .news__list {
    margin: 0 -8px;
  }
}
.news__item {
  width: 50%;
  padding: 0 6px;
  margin-bottom: 16px;
}
@media screen and (min-width: 480px) {
  .news__item {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 990px) {
  .news__item {
    width: 25%;
    padding: 0 8px;
  }
}
@media screen and (min-width: 1200px) {
  .news__item {
    width: 20%;
  }
}
.news__link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 15px;
  height: 65px;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  padding: 0 20px;
}
@media screen and (min-width: 990px) {
  .news__link {
    height: 92px;
  }
}
.news__link_btc {
  background: linear-gradient(99.11deg, #13B0B4 2.18%, #0091AF 100%);
}
.news__link_black {
  background: #000;
}
.news__link_cmc {
  background: linear-gradient(45deg, #184dff, #2966ff);
}
.news__link_grey {
  background: #F4F4F4;
}
.news__link_dark {
  background: #171F2C;
}
.news__link_violet {
  background: #400190;
}
.news__link_orange {
  background: linear-gradient(92.47deg, #F80278 1.82%, #E75C0E 100.54%);
}
.news__link_taupe-gray {
  background: #272F3A;
}
.news__link_white {
  background: #FEFEFE;
}
.news__link_bloomberg {
  background: linear-gradient(104.28deg, #F61851 4.8%, #154CDC 27.41%);
}
.news__link_blue {
  background: #093967;
}
@media screen and (min-width: 990px) {
  .news__link:hover {
    filter: grayscale(0);
  }
}
.news__img {
  transition: filter 0.3s ease;
  max-width: 178px;
  max-height: 55px;
  width: 100%;
}

.community__title {
  max-width: none;
}
@media screen and (min-width: 990px) {
  .community__title {
    text-align: left;
  }
}
.community__list {
  display: flex;
  flex-wrap: wrap;
  margin: -70px -19px 0;
  padding: 85px 15px 50px;
  background: url("../images/content/rax.svg") top 20px center/160% no-repeat;
}
@media screen and (min-width: 600px) {
  .community__list {
    background: url("../images/content/rax.svg") top 20px center/contain no-repeat;
    padding: 85px 0 50px;
  }
}
@media screen and (min-width: 768px) {
  .community__list {
    background: url("../images/content/rax.svg") top -20px center/contain no-repeat;
    padding: 85px 0;
  }
}
@media screen and (min-width: 1200px) {
  .community__list {
    padding: 130px 0;
  }
}
.community__item {
  width: 100%;
  padding: 0 4px;
  margin-bottom: 9px;
}
@media screen and (min-width: 600px) {
  .community__item {
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .community__item {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 990px) {
  .community__item {
    width: 25%;
  }
}
.community__item_big {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .community__item_big {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .community__item_big {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .community__item_big {
    width: 33.3333333333%;
  }
}
.community__link {
  position: relative;
  display: flex;
  text-decoration: none;
  border-radius: 22px;
  padding: 28px 65px 18px 8px;
  align-items: center;
  height: 100%;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(59px);
}
@media screen and (min-width: 600px) {
  .community__link {
    padding: 28px 8px 18px;
  }
}
@media screen and (min-width: 1360px) {
  .community__link {
    padding: 28px 13px 18px;
  }
}
.community__img-wrapper {
  width: 37px;
  height: 37px;
  margin: 0 8px 10px;
  flex: 0 0 auto;
}
@media screen and (min-width: 1200px) {
  .community__img-wrapper {
    margin: 0 9px 10px;
  }
}
@media screen and (min-width: 1360px) {
  .community__img-wrapper {
    width: 48px;
    height: 48px;
  }
}
.community__img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.community__tag {
  position: absolute;
  right: 11px;
  top: 50%;
  background: #1D59F6;
  border-radius: 12px;
  color: #FFF;
  font: 700 11px/1 "Work Sans", sans-serif;
  padding: 7px 6px;
  transform: translateY(-50%);
}
@media screen and (min-width: 600px) {
  .community__tag {
    left: 11px;
    right: auto;
    top: -10px;
    transform: none;
  }
}
@media screen and (min-width: 990px) {
  .community__tag {
    font-size: 14px;
    padding: 9px 5px;
  }
}
.community__content {
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .community__content {
    margin: 0 9px;
  }
}
.community__item-title {
  color: #FFF;
  font: 700 14px/1 "Work Sans", sans-serif;
  margin-bottom: 10px;
  margin-right: 15px;
}
@media screen and (min-width: 1200px) {
  .community__item-title {
    font-size: 18px;
  }
}
.community__description {
  color: #FFF;
  font: 500 13px/1 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 1200px) {
  .community__description {
    font-size: 16px;
  }
}
.community__info-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.community__info-item {
  position: relative;
  margin: 0 5px 10px;
  padding-left: 15px;
  color: #FFF;
  font: 400 13px/1 "Work Sans", sans-serif;
}
@media screen and (min-width: 1200px) {
  .community__info-item {
    font-size: 18px;
    padding-left: 20px;
  }
}
.community__info-img-wrapper {
  position: absolute;
  left: 0;
  top: 1px;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 1200px) {
  .community__info-img-wrapper {
    width: 16px;
    height: 16px;
  }
}
.community__info-img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer__wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
  padding-bottom: 5px;
  padding-top: 140px;
}
@media screen and (min-width: 768px) {
  .footer__wrapper {
    flex-wrap: nowrap;
    padding-top: 0;
  }
}
@media screen and (min-width: 990px) {
  .footer__wrapper {
    margin: 0 -50px;
  }
}
.footer__info-block {
  width: 100%;
  padding: 0 25px;
}
@media screen and (min-width: 768px) {
  .footer__info-block {
    width: 40%;
  }
}
@media screen and (min-width: 990px) {
  .footer__info-block {
    width: 32%;
    padding: 0 50px;
  }
}
.footer__links-block {
  width: 100%;
  padding: 0 25px;
  order: -1;
}
@media screen and (min-width: 768px) {
  .footer__links-block {
    width: 60%;
    order: 0;
  }
}
@media screen and (min-width: 990px) {
  .footer__links-block {
    padding: 0 50px;
    width: 50%;
  }
}
.footer__logo {
  max-width: 157px;
  margin-bottom: 25px;
  position: absolute;
  top: 0;
  left: 25px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .footer__logo {
    position: relative;
    left: auto;
  }
}
@media screen and (min-width: 990px) {
  .footer__logo {
    max-width: 127px;
    margin-bottom: 30px;
  }
}
.footer__socials {
  position: absolute;
  left: 25px;
  top: 85px;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .footer__socials {
    position: relative;
    left: auto;
    top: auto;
  }
}
@media screen and (min-width: 990px) {
  .footer__socials {
    max-width: 205px;
    min-height: 54px;
    position: absolute;
    top: 0;
    right: 50px;
  }
}
@media screen and (min-width: 1200px) {
  .footer__socials {
    max-width: 225px;
  }
}
.footer__info p {
  color: #717584;
  font: 400 12px/1.2 "Work Sans", sans-serif;
  margin-bottom: 17px;
}
@media screen and (min-width: 768px) {
  .footer__info p {
    margin-bottom: 27px;
  }
}
.footer__copyright {
  color: #717584;
  font: 500 13px/1.3 "Work Sans", sans-serif;
  margin-bottom: 17px;
}
@media screen and (min-width: 768px) {
  .footer__copyright {
    position: absolute;
    bottom: 0;
    right: 50px;
    margin-bottom: 27px;
  }
}
.footer__links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .footer__links {
    margin-bottom: 27px;
  }
}
.footer__item {
  width: 49%;
  margin-bottom: 10px;
  padding-right: 15px;
}
@media screen and (min-width: 768px) {
  .footer__item {
    margin-bottom: 15px;
  }
}
.footer__link {
  display: inline-block;
  text-decoration: none;
  color: #FFF;
  font: 700 13px/1.3 "Work Sans", sans-serif;
}
.footer__link:active {
  color: #7F00FF;
}
@media screen and (min-width: 768px) {
  .footer__link {
    font-size: 16px;
  }
}
@media screen and (min-width: 990px) {
  .footer__link:hover {
    color: #7F00FF;
  }
}
.footer__link_disabled {
  color: #5C5C5C;
}
.footer__link_disabled:active {
  color: #5C5C5C;
}
@media screen and (min-width: 990px) {
  .footer__link_disabled:hover {
    color: #5C5C5C;
  }
}
.footer__link-info {
  display: inline-block;
  padding: 1px 8px;
  color: #FFF;
  background: #1D59F6;
  border-radius: 12px;
}

.base {
  background: rgba(28, 29, 35, 0.6);
  overflow: hidden;
}
.base__cover {
  padding-top: 65px;
  padding-bottom: 60px;
}
@media screen and (min-width: 990px) {
  .base__cover {
    padding-bottom: 70px;
    padding-top: 127px;
  }
}
.base__img-wrapper {
  margin: -3% -15% -8%;
}
@media screen and (min-width: 990px) {
  .base__img-wrapper {
    margin: -3% -15% -14%;
  }
}
.base__img {
  width: 100%;
  max-width: none;
}
.base__full {
  text-align: center;
}
@media screen and (min-width: 990px) {
  .base__full {
    display: none;
  }
}

.ecosystem__cover {
  padding-top: 63px;
  padding-bottom: 5px;
}
@media screen and (min-width: 990px) {
  .ecosystem__cover {
    padding-top: 177px;
  }
}
.ecosystem__title {
  margin-bottom: 32px;
}
@media screen and (min-width: 990px) {
  .ecosystem__title {
    margin-bottom: 80px;
  }
}
.ecosystem__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media screen and (min-width: 768px) {
  .ecosystem__list {
    margin: 0 -17px;
  }
}
.ecosystem__item {
  padding: 0 10px;
  margin-bottom: 20px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .ecosystem__item {
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .ecosystem__item {
    padding: 0 17px;
    margin-bottom: 34px;
  }
}
@media screen and (min-width: 990px) {
  .ecosystem__item {
    width: 33.3333333333%;
  }
}
.ecosystem__item_big {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .ecosystem__item_big {
    width: 100%;
  }
}
@media screen and (min-width: 990px) {
  .ecosystem__item_big {
    width: 66.6666666666%;
  }
}
.ecosystem__content {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .ecosystem__content {
    margin-bottom: 0;
  }
}
.ecosystem__content p {
  color: #FFF;
  font: 700 16px/1.31 "Work Sans", sans-serif;
  margin-bottom: 0;
}
@media screen and (min-width: 600px) {
  .ecosystem__content p {
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) {
  .ecosystem__content p {
    font-size: 28px;
  }
}
@media screen and (min-width: 1200px) {
  .ecosystem__content p {
    font-size: 30px;
  }
}
.ecosystem__text {
  overflow: hidden;
  position: relative;
  padding: 2px;
  border-radius: 21px;
}
.ecosystem__text:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #27e55c 0%, #587bff 50%, #b518ff 100%);
  z-index: -1;
}
.ecosystem__text-content {
  border-radius: 21px;
  padding: 22px 20px 10px;
  background: #090A0E;
}
@media screen and (min-width: 768px) {
  .ecosystem__text-content {
    padding: 32px 25px 10px;
  }
}
.ecosystem__text-content p {
  color: #FFF;
  font: 600 14px/1.33 "Work Sans", sans-serif;
  margin-bottom: 18px;
}
@media screen and (min-width: 768px) {
  .ecosystem__text-content p {
    font-size: 15px;
    margin-bottom: 25px;
  }
}

.burning {
  background: #151721;
}
@media screen and (min-width: 990px) {
  .burning {
    background: rgba(28, 29, 35, 0.6);
  }
}
.burning__cover {
  padding-top: 45px;
  padding-bottom: 45px;
}
@media screen and (min-width: 990px) {
  .burning__cover {
    padding-top: 89px;
    padding-bottom: 100px;
  }
}
.burning__title {
  position: relative;
  z-index: 1;
}
.burning__wrapper {
  position: relative;
}
.burning__img-wrapper {
  position: relative;
  display: block;
  margin: -10% -30% 0 -20%;
}
@media screen and (min-width: 990px) {
  .burning__img-wrapper {
    width: 33%;
    margin: 0 auto;
  }
}
.burning__img {
  max-width: none;
  width: 100%;
}
.burning__text-wrapper {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  left: 0;
  height: 100%;
}
@media screen and (min-width: 990px) {
  .burning__text-wrapper {
    display: flex;
  }
}
.burning__text {
  position: relative;
  display: flex;
  width: 45%;
  text-align: center;
  justify-content: flex-start;
}
.burning__text::before {
  content: "";
  display: block;
  width: 47%;
  height: 1px;
  background: linear-gradient(82.06deg, #7F00FF -2.67%, rgba(255, 255, 255, 0.6) 91.35%);
  position: absolute;
  bottom: 40%;
  right: -10%;
  transform: rotate(58deg);
}
.burning__text:after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: absolute;
  bottom: -17%;
  right: -1%;
}
.burning__text_first:before {
  width: 39%;
  bottom: 36%;
  transform: rotate(67deg);
}
@media screen and (min-width: 1200px) {
  .burning__text_first:before {
    transform: rotate(58deg);
    bottom: 40%;
    width: 47%;
  }
}
.burning__text_second:before {
  width: 50%;
  transform: rotate(129deg);
  right: auto;
  left: -20%;
  bottom: 33%;
}
@media screen and (min-width: 1200px) {
  .burning__text_second:before {
    width: 63%;
    bottom: 34%;
    transform: rotate(137deg);
  }
}
.burning__text_second:after {
  bottom: -25%;
  right: auto;
  left: -14%;
}
.burning__text_third:before {
  width: 18%;
  transform: rotate(43deg);
  right: 8%;
  bottom: 69%;
}
@media screen and (min-width: 1200px) {
  .burning__text_third:before {
    width: 34%;
    transform: rotate(25deg);
  }
}
.burning__text_third:after {
  bottom: 45%;
  right: 7%;
}
.burning__text_fourth:before {
  width: 30%;
  transform: rotate(147deg);
  right: auto;
  left: 5%;
  bottom: 65%;
}
@media screen and (min-width: 1200px) {
  .burning__text_fourth:before {
    width: 43%;
    transform: rotate(155deg);
  }
}
.burning__text_fourth:after {
  bottom: 37%;
  right: auto;
  left: 4%;
}
.burning__text_fifth:before {
  width: 11%;
  transform: rotate(-36deg);
  right: 9%;
  bottom: auto;
  top: -8%;
}
@media screen and (min-width: 1200px) {
  .burning__text_fifth:before {
    width: 28%;
    transform: rotate(-18deg);
  }
}
.burning__text_fifth:after {
  bottom: auto;
  top: -24%;
  right: 7%;
}
.burning__text_sixth:before {
  width: 39%;
  transform: rotate(185deg);
  right: auto;
  left: -10%;
  bottom: auto;
  top: 1%;
}
@media screen and (min-width: 1200px) {
  .burning__text_sixth:before {
    left: -11%;
    width: 56%;
  }
}
.burning__text_sixth:after {
  bottom: auto;
  top: -8%;
  right: auto;
  left: -14%;
}
.burning__text:nth-child(2n) {
  justify-content: flex-end;
}
.burning__text-content {
  max-width: 360px;
  width: 100%;
}
.burning__text_first .burning__text-content {
  margin-left: 10%;
}
@media screen and (min-width: 1360px) {
  .burning__text_first .burning__text-content {
    margin-left: 15%;
  }
}
.burning__text_second .burning__text-content {
  margin-right: 15%;
}
.burning__text-title {
  color: #FFF;
  font: 700 26px/1.11 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
.burning__text-description {
  color: #717584;
  font: 500 15px/1.17 "Work Sans", sans-serif;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.burning__text_first .burning__text-description {
  max-width: 280px;
}
.burning__text_second .burning__text-description {
  max-width: 200px;
}
.burning__text_third .burning__text-description {
  max-width: 336px;
}
.burning__text_fourth .burning__text-description {
  max-width: 195px;
}
.burning__text_sixth .burning__text-description {
  max-width: 295px;
}

.backers__cover {
  padding-top: 52px;
  padding-bottom: 25px;
}
@media screen and (min-width: 990px) {
  .backers__cover {
    padding-top: 93px;
    padding-bottom: 80px;
  }
}
.backers__title {
  color: #fff;
  font: 700 25px/1.1 "Work Sans", sans-serif;
  text-align: center;
  margin-bottom: 43px;
}
@media screen and (min-width: 768px) {
  .backers__title {
    font-size: 35px;
  }
}
.backers__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
}
@media screen and (min-width: 990px) {
  .backers__list {
    margin: 0 -25px;
  }
}
.backers__item {
  padding: 0 10px;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
}
@media screen and (min-width: 375px) {
  .backers__item {
    width: 50%;
  }
}
@media screen and (min-width: 480px) {
  .backers__item {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 990px) {
  .backers__item {
    padding: 0 25px;
    width: 25%;
  }
}
.backers__basker {
  width: 100%;
  padding: 5px 10px;
  max-height: 109px;
  align-items: center;
}
@media screen and (min-width: 990px) {
  .backers__basker {
    padding: 17px 27px;
  }
}
.backers__img {
  max-width: none;
  width: 100%;
  height: 100%;
}

.benefits__cover {
  padding-top: 25px;
  padding-bottom: 80px;
}
@media screen and (min-width: 990px) {
  .benefits__cover {
    padding-bottom: 160px;
    padding-top: 50px;
  }
}
.benefits__title {
  margin-bottom: 25px;
}
@media screen and (min-width: 990px) {
  .benefits__title {
    margin-bottom: 69px;
  }
}
.benefits__table {
  background: rgba(40, 48, 63, 0.42);
  backdrop-filter: blur(88.5px);
  border-radius: 43px;
  padding: 7px;
  margin: 0 -15px;
}
@media screen and (min-width: 768px) {
  .benefits__table {
    padding: 15px;
    margin: 0;
  }
}
.benefits__table-content {
  display: flex;
  justify-content: space-between;
}
.benefits__slider {
  display: flex;
  max-width: 35%;
  padding-right: 20px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .benefits__slider {
    max-width: 55%;
  }
}
@media screen and (min-width: 1360px) {
  .benefits__slider {
    max-width: 50%;
  }
}
.benefits__column {
  border-radius: 29px;
  max-width: 110px;
  width: 100%;
  flex: 0 0 auto;
}
@media screen and (min-width: 768px) {
  .benefits__column {
    max-width: 156px;
  }
}
@media screen and (min-width: 1200px) {
  .benefits__column {
    max-width: 206px;
  }
}
.benefits__column_head {
  max-width: 276px;
  width: 100%;
  flex: 0 1 auto;
}
@media screen and (min-width: 990px) {
  .benefits__column_head {
    min-width: 200px;
  }
}
@media screen and (min-width: 1200px) {
  .benefits__column_head {
    max-width: 276px;
  }
}
.benefits__column_green {
  background: linear-gradient(180deg, rgba(0, 255, 102, 0.162) 0%, rgba(0, 255, 102, 0.21) 20.83%, rgba(0, 255, 102, 0.336) 47.4%, rgba(0, 255, 102, 0.114) 100%);
}
.benefits__slide .benefits__column {
  max-width: none;
}
.benefits__cell {
  position: relative;
  min-height: 82px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.5px;
}
@media screen and (min-width: 480px) {
  .benefits__cell {
    min-height: 70px;
    padding: 15px;
    letter-spacing: 0;
  }
}
@media screen and (min-width: 768px) {
  .benefits__cell {
    min-height: 100px;
  }
}
@media screen and (min-width: 1200px) {
  .benefits__cell {
    padding: 15px 30px;
  }
}
.benefits__cell:first-child {
  min-height: 100px;
}
.benefits__cell_black {
  display: block;
}
@media screen and (min-width: 990px) {
  .benefits__cell_black {
    background: #090A0E;
    border-radius: 0 0 41px 0;
    margin: -15px 0 0 -15px;
  }
}
@media screen and (min-width: 990px) {
  .benefits__cell_black:before {
    content: "";
    display: block;
    position: absolute;
    top: -50%;
    right: -50px;
    width: 100px;
    height: 100%;
    border-radius: 50%;
    background: #090A0E;
  }
}
@media screen and (min-width: 990px) {
  .benefits__cell_black:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -100px;
    width: 100px;
    height: 100%;
    border-radius: 41%;
    background: #161A22;
  }
}
.benefits__cell-black {
  display: none;
  min-height: 100px;
  margin: -15px -15px 0;
}
@media screen and (min-width: 990px) {
  .benefits__cell-black {
    display: block;
  }
}
@media screen and (min-width: 990px) {
  .benefits__cell-black:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -50%;
    left: -50px;
    width: 100px;
    height: 100%;
    border-radius: 50%;
    background: #090A0E;
  }
}
@media screen and (min-width: 990px) {
  .benefits__cell-black:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100px;
    height: 100%;
    border-radius: 41%;
    background: #161A22;
  }
}
.benefits__column_head .benefits__cell {
  justify-content: flex-start;
}
.benefits__img-wrapper {
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .benefits__img-wrapper {
    margin: 0 -10px;
  }
}
.benefits__img-wrapper_raysx {
  max-width: 80px;
}
@media screen and (min-width: 480px) {
  .benefits__img-wrapper_raysx {
    max-width: 134px;
  }
}
.benefits__img {
  max-width: none;
  width: 100%;
}
.benefits__icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.benefits__icon {
  width: 25px;
  height: 25px;
}
@media screen and (min-width: 1200px) {
  .benefits__icon {
    width: 34px;
    height: 34px;
  }
}
.benefits__icon-text {
  position: absolute;
  top: -75%;
  left: 50%;
  color: #FFF;
  font: 600 10px/1 "Work Sans", sans-serif;
  padding: 5px 10px;
  background: #1D59F6;
  border-radius: 12px;
  white-space: nowrap;
  transform: translateX(-50%);
}
@media screen and (min-width: 1200px) {
  .benefits__icon-text {
    transform: none;
    top: -30%;
    font-size: 12px;
  }
}
.benefits__text {
  text-align: center;
  color: #FFF;
  font: 700 12px/1.5 "Work Sans", sans-serif;
}
@media screen and (min-width: 990px) {
  .benefits__text {
    font-size: 16px;
  }
}
.benefits__text-span {
  font-weight: 400;
  color: #717584;
}
.benefits__column_head .benefits__text {
  text-align: left;
}
.benefits__prev-arrow {
  left: -15px;
  top: 50px;
}
.benefits__next-arrow {
  top: 50px;
  right: 10px;
}

.buy__cover {
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media screen and (min-width: 990px) {
  .buy__cover {
    padding-bottom: 40px;
  }
}
.buy__img-wrapper {
  display: none;
  position: absolute;
  top: -300px;
  left: -770px;
  width: 1037px;
}
@media screen and (min-width: 1600px) {
  .buy__img-wrapper {
    display: block;
  }
}
.buy__img-wrapper_right {
  top: -40px;
  left: auto;
  right: -620px;
  width: 709px;
}
.buy__img {
  max-width: none;
  width: 100%;
}
.buy__wrapper {
  position: relative;
}
.buy__title {
  margin-bottom: 25px;
  padding: 0 15px;
}
@media screen and (min-width: 1200px) {
  .buy__title {
    margin-bottom: 50px;
  }
}
.buy__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -4px 25px;
}
@media screen and (min-width: 600px) {
  .buy__links {
    margin: 0 -7px 25px;
  }
}
@media screen and (min-width: 1200px) {
  .buy__links {
    margin: 0 -7px 50px;
  }
}
.buy__links-item {
  padding: 0 4px;
  margin-bottom: 14px;
}
@media screen and (min-width: 600px) {
  .buy__links-item {
    padding: 0 7px;
  }
}
.buy__links-link {
  display: block;
  padding: 8px 12px;
  text-align: center;
  color: #FFF;
  font: 700 12px/1 "Work Sans", sans-serif;
  background: #252629;
  border-radius: 15px;
  text-decoration: none;
}
@media screen and (min-width: 600px) {
  .buy__links-link {
    font-size: 14px;
  }
}
@media screen and (min-width: 990px) {
  .buy__links-link {
    font-size: 16px;
    padding: 12px 20px;
  }
}
@media screen and (min-width: 1200px) {
  .buy__links-link:hover, .buy__links-link:active {
    text-decoration: underline;
  }
}
.buy__tabs-nav {
  margin-bottom: 16px;
}
.buy__tabs {
  margin-bottom: 50px;
}

.invite__cover {
  padding-top: 40px;
  padding-bottom: 30px;
}
@media screen and (min-width: 990px) {
  .invite__cover {
    padding-top: 104px;
    padding-bottom: 90px;
  }
}
.invite__title {
  max-width: none;
  margin-bottom: 28px;
}
.invite__subtitle {
  color: #717584;
  font: 500 13px/1.4 "Work Sans", sans-serif;
  text-align: center;
  max-width: 1096px;
  margin: 0 auto 25px;
}
@media screen and (min-width: 600px) {
  .invite__subtitle {
    font-size: 15px;
    margin: 0 auto 40px;
  }
}
@media screen and (min-width: 990px) {
  .invite__subtitle {
    font-size: 18px;
    margin: 0 auto 60px;
  }
}
.invite__steps {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px 5px;
}
@media screen and (min-width: 768px) {
  .invite__steps {
    margin: 0 -17px;
  }
}
.invite__steps-item {
  padding: 0 6px;
  width: 50%;
  margin-bottom: 12px;
}
@media screen and (min-width: 768px) {
  .invite__steps-item {
    padding: 0 17px;
    margin-bottom: 34px;
  }
}
@media screen and (min-width: 990px) {
  .invite__steps-item {
    width: 33.3333333333%;
  }
}
.invite__steps-item_big {
  width: 100%;
}
@media screen and (min-width: 990px) {
  .invite__steps-item_big {
    width: 33.3333333333%;
  }
}
.invite__step {
  height: 100%;
  background: #202127;
  border-radius: 30px;
  padding: 22px;
}
@media screen and (min-width: 600px) {
  .invite__step {
    padding: 37px;
  }
}
.invite__step-title {
  font: 700 30px/1 "Work Sans", sans-serif;
  margin-bottom: 13px;
  background: linear-gradient(95.5deg, #587BFF 0.5%, #B518FF 33.98%, #B518FF 97.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (min-width: 480px) {
  .invite__step-title {
    font-size: 35px;
  }
}
@media screen and (min-width: 990px) {
  .invite__step-title {
    font-size: 42px;
  }
}
.invite__step-text {
  color: #FFF;
  font: 700 13px/1.5 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .invite__step-text {
    font-size: 15px;
  }
}
@media screen and (min-width: 990px) {
  .invite__step-text {
    font-size: 18px;
  }
}
.invite__info {
  margin-bottom: 34px;
  border-radius: 24px;
  padding: 1px;
  background: linear-gradient(90deg, #27e55c 0%, #587bff 50%, #b518ff 100%);
}
.invite__info-text {
  background: #202127;
  color: #FFF;
  text-align: center;
  padding: 20px;
  font: 700 14px/1.1 "Work Sans", sans-serif;
  border-radius: 24px;
}
@media screen and (min-width: 480px) {
  .invite__info-text {
    font-size: 16px;
  }
}
@media screen and (min-width: 990px) {
  .invite__info-text {
    font-size: 18px;
  }
}
.invite__info-span {
  background: linear-gradient(95.5deg, #587BFF 0.5%, #B518FF 33.98%, #B518FF 97.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.distribution__cover {
  padding-top: 50px;
  padding-bottom: 35px;
}
@media screen and (min-width: 990px) {
  .distribution__cover {
    padding-bottom: 70px;
  }
}
.distribution__title {
  margin-bottom: 25px;
}
@media screen and (min-width: 600px) {
  .distribution__title {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 990px) {
  .distribution__title {
    margin-bottom: 80px;
  }
}
.distribution__table {
  margin-bottom: 25px;
}

.key__cover {
  padding-top: 30px;
  padding-bottom: 35px;
}
@media screen and (min-width: 990px) {
  .key__cover {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}
.key__title {
  margin-bottom: 22px;
}
@media screen and (min-width: 480px) {
  .key__title {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 990px) {
  .key__title {
    margin-bottom: 62px;
  }
}
.key__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}
.key__item {
  padding: 0 16px;
  margin-bottom: 19px;
  width: 100%;
  min-height: 54px;
}
@media screen and (min-width: 768px) {
  .key__item {
    width: 50%;
  }
}
@media screen and (min-width: 990px) {
  .key__item {
    min-height: 68px;
  }
}
.key__item-content {
  padding: 10px 24px;
  border-radius: 15px;
  background: #212127;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
@media screen and (min-width: 990px) {
  .key__item-content {
    border-radius: 21px;
  }
}
.key__item-content_black {
  background: #14151B;
}
.key__item-rax-bg {
  height: 100%;
  padding: 1px;
  background: linear-gradient(90deg, #27e55c 0%, #587bff 50%, #b518ff 100%);
  border-radius: 15px;
}
@media screen and (min-width: 990px) {
  .key__item-rax-bg {
    border-radius: 21px;
  }
}
.key__item-title {
  color: #FFF;
  font: 600 13px/1.4 "Work Sans", sans-serif;
  margin-right: 10px;
}
@media screen and (min-width: 990px) {
  .key__item-title {
    font-size: 16px;
  }
}
.key__item-text {
  color: #FFF;
  font: 300 16px/1.4 "Work Sans", sans-serif;
}
@media screen and (min-width: 990px) {
  .key__item-text {
    font-size: 22px;
  }
}
.key__item-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
}
.key__item-field {
  padding: 0 7px;
  display: flex;
  align-items: center;
}
.key__item-img {
  flex: 0 0 auto;
  margin-right: 14px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.key__rax-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(122.71deg, #9225FF 11.73%, #00FF66 129.28%);
  margin-right: 10px;
}
@media screen and (min-width: 990px) {
  .key__rax-wrapper {
    width: 40px;
    height: 40px;
  }
}
.key__rax-text {
  color: #FFF;
  font: 900 11px/1.4 "Work Sans", sans-serif;
  text-align: center;
}
@media screen and (min-width: 990px) {
  .key__rax-text {
    font-size: 14px;
  }
}

.presale__cover {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 990px) {
  .presale__cover {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.presale__title {
  margin-bottom: 36px;
}
@media screen and (min-width: 768px) {
  .presale__title {
    font-size: 30px;
  }
}
@media screen and (min-width: 990px) {
  .presale__title {
    font-size: 30px;
    margin-bottom: 50px;
  }
}
.presale__list {
  margin-bottom: 30px;
}
.presale__item {
  position: relative;
  margin-bottom: 17px;
  cursor: pointer;
  border-radius: 15px;
  background: #212127;
  padding: 20px 20px 5px 50px;
}
@media screen and (min-width: 990px) {
  .presale__item {
    padding: 20px 20px 5px 65px;
  }
}
.presale__item:before, .presale__item:after {
  content: "";
  display: block;
  width: 18px;
  height: 2px;
  background: #1D59F6;
  position: absolute;
  left: 16px;
  top: 30px;
}
@media screen and (min-width: 990px) {
  .presale__item:before, .presale__item:after {
    width: 24px;
    left: 20px;
    top: 35px;
  }
}
.presale__item:after {
  transform: rotate(90deg);
}
.presale__item.active:after {
  display: none;
}
.presale__question {
  color: #FFF;
  font: 700 14px/1.3 "Work Sans", sans-serif;
  margin-bottom: 15px;
}
@media screen and (min-width: 990px) {
  .presale__question {
    font-size: 22px;
  }
}
.presale__text {
  display: none;
}
.presale__text p {
  color: #FFF;
  font: 500 11px/1.5 "Work Sans", sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 990px) {
  .presale__text p {
    font-size: 14px;
  }
}
.presale__item.active .presale__text {
  display: block;
  margin-bottom: 20px;
}