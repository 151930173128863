.full {
  text-decoration: none;
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 15px;
  padding: 5px 35px 5px 20px;
  &__text {
    color: $color-white;
    font: $bold 14px / 1.5 $font;
  }
  &__icon {
    fill: $color-white;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}