.utility {
  &__wrapper {
    padding-bottom: 50px;
    @media screen and (min-width: 990px) {
      padding-bottom: 180px;
      padding-top: 40px;
    } 
  }
  &__title {
    margin-bottom: 13px;
    @media screen and (min-width: 990px) {
      margin-bottom: 80px;
    }
  }
  &__description {
    text-align: center;
    max-width: 865px;
    margin: 0 auto 20px;
    padding: 0 15px;
    color: $color-white;
    font: $regular 13px / 1.3 $font;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      padding: 0;
    } 
  }
  &__img-wrapper {
    margin-bottom: 60px;
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__full {
    text-align: center;
    margin-bottom: 24px;
    margin-top: 10px;
    @media screen and (min-width: 990px) {
      display: none;
    }
  }
  &__list {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    @media screen and (min-width: 990px) {
      flex-wrap: nowrap;
    }
    @media screen and (min-width: 1200px) {
      margin: 0 -22px;
    }
    &-item {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 18px;
      @media screen and (min-width: 375px) {
        width: 50%;
      }
      @media screen and (min-width: 990px) {
        width: 25%;
        margin-bottom: 30px;
      }
      @media screen and (min-width: 1200px) {
        padding: 0 22px;
      }
    }
  }
}