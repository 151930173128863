.footer {
  &__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    padding-bottom: 5px;
    padding-top: 140px;
    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      padding-top: 0;
    }
    @media screen and (min-width: 990px) {
      margin: 0 -50px;
    }
  }
  &__info-block {
    width: 100%;
    padding: 0 25px;
    @media screen and (min-width: 768px) {
      width: 40%;
    }
    @media screen and (min-width: 990px) {
      width: 32%;
      padding: 0 50px;
    }
  }
  &__links-block {
    width: 100%;
    padding: 0 25px;
    order: -1;
    @media screen and (min-width: 768px) {
      width: 60%;
      order: 0;
    }
    @media screen and (min-width: 990px) {
      padding: 0 50px;
      width: 50%;
    }
  }
  &__logo {
    max-width: 157px;
    margin-bottom: 25px;
    position: absolute;
    top: 0;
    left: 25px;
    width: 100%;
    @media screen and (min-width: 768px) {
      position: relative;
      left: auto;
    }
    @media screen and (min-width: 990px) {
      max-width: 127px;
      margin-bottom: 30px;
    } 
  }
  &__socials {
    position: absolute;
    left: 25px;
    top: 85px;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      position: relative;
      left: auto;
      top: auto;
    }
    @media screen and (min-width: 990px) {
      max-width: 205px;
      min-height: 54px;
      position: absolute;
      top: 0;
      right: 50px;
    }
    @media screen and (min-width: 1200px) {
      max-width: 225px;
    }
  }
  &__info {
    p {
      color: #717584;
      font: $regular 12px / 1.2 $font;
      margin-bottom: 17px;
      @media screen and (min-width: 768px) {
        margin-bottom: 27px;
      }
    }
  }
  &__copyright {
    color: #717584;
    font: $medium 13px / 1.3 $font;
    margin-bottom: 17px;
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 0;
      right: 50px;
      margin-bottom: 27px;
    }
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      margin-bottom: 27px;
    }
  }
  &__item {
    width: 49%;
    margin-bottom: 10px;
    padding-right: 15px;
    @media screen and (min-width: 768px) {
      margin-bottom: 15px;
    }
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    color: $color-white;
    font: $bold 13px / 1.3 $font;
    &:active {
      color: $color-violet;
    }
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 990px) {
      &:hover {
        color: $color-violet;
      }
    }
    &_disabled {
      color: #5C5C5C;
      &:active {
        color: #5C5C5C;
      }
      @media screen and (min-width: 990px) {
        &:hover {
          color: #5C5C5C;
        }
      }
    }
    &-info {
      display: inline-block;
      padding: 1px 8px;
      color: $color-white;
      background: #1D59F6;
      border-radius: 12px;
    }
  }
}