.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease;
  z-index: 1111;
  background: #090A0EDE;
  backdrop-filter: blur(24px);
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    @media screen and (min-width: 768px) {
      padding-top: 20px;
    }
  }
  &__logo {
    flex: 0 0 auto;
    width: 94px;
    margin-bottom: 27px;
    margin-right: 25px;
    @media screen and (min-width: 768px) {
      width: 127px;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    margin: 0 -11px 10px;
    @media screen and (min-width: 990px) {
      margin: 0 -22px 16px;
    }
  }
  &__links-wrapper {
    padding: 0 22px;
    display: none;
    @media screen and (min-width: 990px) {
      display: block;
    }
  }
  &__socials-wrapper {
    display: none;
    @media screen and (min-width: 990px) {
      padding: 0 22px;
      display: block;
    }

    &-mobile {
      padding: 0;
      display: flex;
      justify-content: center;
      margin-top: 16px;
      @media screen and (min-width: 990px) {
        display: none;
      }
    }
  }
  &__app-links-wrapper {

  }
  &__app-links {
    display: flex;
    align-items: center;
    margin: 0;
    &-item {
      margin-bottom: 10px;

      &:last-of-type {
        margin-left: 8px;

        animation: shake-animation 1s infinite ease-out alternate;

        &:hover {
          animation: unset;
        }
      }
    }
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
    &-item {
      padding: 0 18px;
      margin-bottom: 10px;
    }
    &-link {
      display: block;
      text-decoration: none;
      color: $color-white;
      font: $bold 16px / 1.3 $font;
      transition: color 0.3s ease;
      &:active {
        color: $color-violet;
      }
      @media screen and (min-width: 990px) {
        &:hover {
          color: $color-violet;
        }
      }
    }
  }
  &__socials {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    align-items: center;
    @media screen and (min-width: 990px) {
      margin: 0 -15px;
    }
    &-item {
      padding: 0 8px;
      margin-bottom: 10px;
      @media screen and (min-width: 990px) {
        padding: 0 15px;
      }
    }
    &-link {
      display: block;
      text-decoration: none;
      &_whitelist {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(95.5deg, #587BFF 0.5%, #B518FF 97.9%);
        padding: 7.5px 12.5px;
        border-radius: 52px;
        font: $bold 10px / 1.3 $font;
        transition: box-shadow 0.3s ease;
        @media screen and (min-width: 375px) {
          font-size: 12px;
        }
        @media screen and (min-width: 480px) {
          width: 136px;
          height: 40px;
          font-size: 14px;
        }
        @media screen and (min-width: 1200px) {
          width: 136px;
          height: 40px;

          &:hover,
          &:active {
            box-shadow: 0 7px 5px RGBA(181,24,255,.5);
          }
        } 
      }
    }
    &-icon {
      fill: #C8D0E6;
      opacity: 0.3;
      transition: opacity 0.3s ease;
      &:active {
        opacity: 1;
      }
      &:hover {
        @media screen and (min-width: 990px) {
          opacity: 1;
        }
      }
    }
  }
  &__pull {
    padding: 0 11px;
    margin-bottom: 10px;
    @media screen and (min-width: 990px) {
      display: none;
    }
  }
  &__menu {
    transform: scaleY(0);
    transform-origin: 0 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    &-list {
      margin: 0;
    }
    &-item {
      text-align: center;
      margin-bottom: 10px;
    }
    &-link {
      text-decoration: none;
      display: inline-block;
      padding: 5px;
      color: $color-white;
      font: $bold 16px / 1.3 $font;
    }
  }
  &.active {
    background: #08161A;
    backdrop-filter: blur(33px);
    border-radius: 0px 0px 20px 20px;
    padding-bottom: 30px;
    .header__wrapper {
      padding-bottom: 0;
    }
    .header__menu {
      position: relative;
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }
}

@keyframes shake-animation {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
