.base {
  background: rgba(28, 29, 35, 0.6);
  overflow: hidden;
  &__cover {
    padding-top: 65px;
    padding-bottom: 60px;
    @media screen and (min-width: 990px) {
      padding-bottom: 70px;
      padding-top: 127px;
    }
  }
  &__img-wrapper {
    margin: -3% -15% -8%;
    @media screen and (min-width: 990px) {
      margin: -3% -15% -14%;
    } 
  }
  &__img {
    width: 100%;
    max-width: none;
  }
  &__full {
    text-align: center;
    @media screen and (min-width: 990px) {
      display: none;
    }
  }
}