.community {
  &__title {
    max-width: none;
    @media screen and (min-width: 990px) {
      text-align: left;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -70px -19px 0;
    padding: 85px 15px 50px;
    background: url('../images/content/rax.svg') top 20px center/160% no-repeat;
    @media screen and (min-width: 600px) {
      background: url('../images/content/rax.svg') top 20px center/contain no-repeat;
      padding: 85px 0 50px;
    }
    @media screen and (min-width: 768px) {
      background: url('../images/content/rax.svg') top -20px center/contain no-repeat;
      padding: 85px 0;
    } 
    @media screen and (min-width: 1200px) {
      padding: 130px 0;
    } 
  }
  &__item {
    width: 100%;
    padding: 0 4px;
    margin-bottom: 9px;
    @media screen and (min-width: 600px) {
      width: 50%;
    }
    @media screen and (min-width: 768px) {
      width: 33.3333333333%;
    }
    @media screen and (min-width: 990px) {
      width: 25%;
    }
    &_big {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 100%;
      }
      @media screen and (min-width: 768px) {
        width: 50%;
      }
      @media screen and (min-width: 990px) {
        width: 33.3333333333%;
      }
    }
  }
  &__link {
    position: relative;
    display: flex;
    text-decoration: none;
    border-radius: 22px;
    padding: 28px 65px 18px 8px;
    align-items: center;
    height: 100%;
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(59px);
    @media screen and (min-width: 600px) {
      padding: 28px 8px 18px;
    }
    @media screen and (min-width: 1360px) {
      padding: 28px 13px 18px;
    }
  }
  &__img-wrapper {
    width: 37px;
    height: 37px;
    margin: 0 8px 10px;
    flex: 0 0 auto;
    @media screen and (min-width: 1200px) {
      margin: 0 9px 10px;
    }
    @media screen and (min-width: 1360px) {
      width: 48px;
      height: 48px;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  &__tag {
    position: absolute;
    right: 11px;
    top: 50%;
    background: #1D59F6;
    border-radius: 12px;
    color: $color-white;
    font: $bold 11px / 1 $font;
    padding: 7px 6px;
    transform: translateY(-50%);
    @media screen and (min-width: 600px) {
      left: 11px;
      right: auto;
      top: -10px;
      transform: none;
    }
    @media screen and (min-width: 990px) {
      font-size: 14px;
      padding: 9px 5px;
    }
  }
  &__content {
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 1200px) {
      margin: 0 9px;
    }
  }
  &__item-title {
    color: $color-white;
    font: $bold 14px / 1 $font;
    margin-bottom: 10px;
    margin-right: 15px;
    @media screen and (min-width: 1200px) {
      font-size: 18px;
    }
  }
  &__description {
    color: $color-white;
    font: $medium 13px / 1 $font;
    margin-bottom: 10px;
    @media screen and (min-width: 1200px) {
      font-size: 16px;
    }
  }
  &__info-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
  &__info-item {
    position: relative;
    margin: 0 5px 10px;
    padding-left: 15px;
    color: $color-white;
    font: $regular 13px / 1 $font;
    @media screen and (min-width: 1200px) {
      font-size: 18px;
      padding-left: 20px;
    }
  }
  &__info-img-wrapper {
    position: absolute;
    left: 0;
    top: 1px;
    width: 12px;
    height: 12px;
    @media screen and (min-width: 1200px) {
      width: 16px;
      height: 16px;
    }
  }
  &__info-img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}