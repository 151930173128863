.presale {
  &__cover {
    padding-top: 40px;
    padding-bottom: 40px;
    @media screen and (min-width: 990px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__title {
    margin-bottom: 36px;
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }   
    @media screen and (min-width: 990px) {
      font-size: 30px;
      margin-bottom: 50px;
    }  
  }
  &__list {
    margin-bottom: 30px;
  }
  &__item {
    position: relative;
    margin-bottom: 17px;
    cursor: pointer;
    border-radius: 15px;
    background: #212127;
    padding: 20px 20px 5px 50px;
    @media screen and (min-width: 990px) {
      padding: 20px 20px 5px 65px;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 2px;
      background: #1D59F6;
      position: absolute;
      left: 16px;
      top: 30px;
      @media screen and (min-width: 990px) {
        width: 24px;
        left: 20px;
        top: 35px;
      } 
    }
    &:after {
      transform: rotate(90deg);
    }
    &.active:after {
      display: none;
    }
  }
  &__question {
    color: $color-white;
    font: $bold 14px / 1.3 $font;
    margin-bottom: 15px;
    @media screen and (min-width: 990px) {
      font-size: 22px;
    }
  }
  &__text {
    display: none;
    p {
      color: $color-white;
      font: $medium 11px / 1.5 $font;
      margin-bottom: 10px;
      @media screen and (min-width: 990px) {
        font-size: 14px;
      }
    }
  }
  &__item.active &__text {
    display: block;
    margin-bottom: 20px;
  }
}