.video {
  margin-top: 75px;
  @media screen and (min-width: 990px) {
    margin-top: 86px;
  }
  &__wrapper {
    position: relative;
    padding-top: 25px;
    padding-bottom: 1px;
    @media screen and (min-width: 990px) {
      padding-top: 35px;
      padding-bottom: 60px;
    }
  }
  &__coins {
    display: none;
    position: absolute;
    top: 0;
    width: 350px;
    @media screen and (min-width: 1200px) {
      display: block;
    } 
    @media screen and (min-width: 1360px) {
      width: 400px;
      top: -20px;
    } 
    &_left {
      left: -165px;
    }
    &_right {
      right: -165px;
    }
    &-img {
      max-width: none;
      width: 100%;
      animation: bounce 3s linear infinite;
    }
  }
  &__title {
    font-family: "Work Sans", sans-serif;
    font-size: 28px;
    text-align: center;
    z-index: 3;
    margin-bottom: 0;

    @media screen and (min-width: 375px) {
      font-size: 32px;
    }
    @media screen and (min-width: 480px) {
      font-size: 68px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 102px;
      font-weight: 200;
      line-height: 120%;
    }
  }

  &__title-linear {
    background: linear-gradient(180deg, #FFF 66.98%, #2A0B52 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    margin: 0 auto 20px;
    width: fit-content;
    font-weight: 700;

    @media screen and (min-width: 1200px) {
      margin: 0 auto 40px;
      font-weight: 700;
    }
  }

  &__subtitle {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    color: #67646B;
    text-align: center;
    z-index: 3;
    margin-bottom: 18px;

    @media screen and (min-width: 375px) {
      font-size: 18px;
    }
    @media screen and (min-width: 480px) {
      font-size: 24px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 27px;
      margin-bottom: 40px;
    }
  }
  &__launch-link {
    display: flex;
    width: 224px;
    height: 54px;
    padding: 7.5px 12.5px;
    justify-content: center;
    align-items: center;
    margin: 0 auto 40px;

    border-radius: 72px;
    background: linear-gradient(96deg, #587BFF 0.5%, #B518FF 97.9%);
    transition: box-shadow 0.3s ease;

    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 700;
    line-height: 138%;
    text-decoration: none;

    @media screen and (min-width: 480px) {
      margin: 0 auto 80px;

      width: 276px;
      height: 76px;

      font-size: 14px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 18px;

      &:hover,
      &:active {
        box-shadow: 0 7px 5px RGBA(181,24,255,.5);
      }
    }
  }
  &__browser {
    position: relative;
    max-width: 1166px;
    margin: 0 auto 34px;
    padding: 0 10px;
    @media screen and (min-width: 1360px) {
      padding: 0;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 80%;
      border-radius: 50%;
      background: #8123F9;
      filter: blur(60px);
      @media screen and (min-width: 480px) {
        filter: blur(110px);
        top: 10%;
        height: 60%;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 50px;
      width: 60%;
      height: 80%;
      border-radius: 50%;
      background: #2FD5B7;
      filter: blur(60px);
      @media screen and (min-width: 480px) {
        filter: blur(110px);
        top: -2%;
        height: 90%;
      }
    }
  }
  &__spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.disabled {
      display: none;
    }
  }
  &__spinner {
    height: 60px;
    width: 60px;
    border: 3px solid transparent;
    border-top-color: #A04668;
    margin: -30px;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    &:before,
    &:after {
      content:'';
      display: block;
      position: absolute;
      border: 3px solid transparent;
      border-radius: 50%;
    }
    &:before {
      border-top-color: #254E70;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      animation: spin 3s linear infinite;
    }
    &:after {
      border-top-color: #FFFBFE;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;  
      animation: spin 4s linear infinite;
    }
  }
  &__video {
    position: relative;		
    z-index: 1;
    border-radius: 9px;
    width: 100%;
    height: auto;
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 -8px;
    &-item {
      padding: 0 8px;
      margin-bottom: 16px;
      width: 100%;
      @media screen and (min-width: 375px) {
        width: 50%;
      }
      @media screen and (min-width: 480px) {
        width: auto;
      }
    }
    &-link {
      display: block;
      text-decoration: none;
      color: $color-white;
      text-align: center;
      font: $bold 14px / 1.3 $font;
      padding: 16px 23px 14px;
      background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
      border-radius: 15px;
      transition: box-shadow 0.3s ease;
      @media screen and (min-width: 480px) {
        min-width: 357px;
        font-size: 16px;
      } 
      @media screen and (min-width: 1200px) {
        &:hover,
        &:active {
          box-shadow: 0 7px 5px RGBA(88,123,255,.5);
        }
      }
    }
  }
}