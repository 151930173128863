.benefits {
  &__cover {
    padding-top: 25px;
    padding-bottom: 80px;
    @media screen and (min-width: 990px) {
      padding-bottom: 160px;
      padding-top: 50px;
    }
  }
  &__title {
    margin-bottom: 25px;
    @media screen and (min-width: 990px) {
      margin-bottom: 69px;
    }
  }
  &__table {
    background: rgba(40, 48, 63, 0.42);
    backdrop-filter: blur(88.5px);
    border-radius: 43px;
    padding: 7px;
    margin: 0 -15px;
    @media screen and (min-width: 768px) {
      padding: 15px;
      margin: 0;
    }
    &-content {
      display: flex;
      justify-content: space-between;
    }
  }
  &__slider {
    display: flex;
    max-width: 35%;
    padding-right: 20px;
    width: 100%;
    @media screen and (min-width: 768px) {
      max-width: 55%;
    }
    @media screen and (min-width: 1360px) {
      max-width: 50%;
    }
  }
  &__column {
    border-radius: 29px;
    max-width: 110px;
    width: 100%;
    flex: 0 0 auto;
    @media screen and (min-width: 768px) {
      max-width: 156px;
    }
    @media screen and (min-width: 1200px) {
      max-width: 206px;
    } 
    &_head {
      max-width: 276px;
      width: 100%;
      flex: 0 1 auto;
      @media screen and (min-width: 990px) {
        min-width: 200px;
      }
      @media screen and (min-width: 1200px) {
        max-width: 276px;
      } 
    }
    &_green {
      background: linear-gradient(180deg, rgba(0, 255, 102, 0.162) 0%, rgba(0, 255, 102, 0.21) 20.83%, rgba(0, 255, 102, 0.336) 47.4%, rgba(0, 255, 102, 0.114) 100%);
    }
  }
  &__slide &__column {
    max-width: none;
  }
  &__cell {
    position: relative;
    min-height: 82px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.5px;
    @media screen and (min-width: 480px) {
      min-height: 70px;
      padding: 15px;
      letter-spacing: 0;
    }
    @media screen and (min-width: 768px) {
      min-height: 100px;
    }
    @media screen and (min-width: 1200px) {
      padding: 15px 30px;
    }
    &:first-child {
      min-height: 100px;
    }
    &_black {
      display: block;
      @media screen and (min-width: 990px) {
        background: $color-black;
        border-radius: 0 0 41px 0;
        margin: -15px 0 0 -15px;
      } 
      &:before {
        @media screen and (min-width: 990px) {
          content: '';
          display: block;
          position: absolute;
          top: -50%;
          right: -50px;
          width: 100px;
          height: 100%;
          border-radius: 50%;
          background: $color-black;
        } 
      }
      &:after {
        @media screen and (min-width: 990px) {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: -100px;
          width: 100px;
          height: 100%;
          border-radius: 41%;
          background: #161A22;
        } 
      }
    }
    &-black {
      display: none;
      min-height: 100px;
      margin: -15px -15px 0;
      @media screen and (min-width: 990px) {
        display: block;
      }
      &:before {
        @media screen and (min-width: 990px) {
          content: '';
          display: block;
          position: absolute;
          bottom: -50%;
          left: -50px;
          width: 100px;
          height: 100%;
          border-radius: 50%;
          background: $color-black;
        }
      }
      &:after {
        @media screen and (min-width: 990px) {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100px;
          height: 100%;
          border-radius: 41%;
          background: #161A22;
        }
      }
    }
  }
  &__column_head &__cell {
    justify-content: flex-start;
  }
  &__img-wrapper {
    margin: 0 auto;
    @media screen and (min-width: 1200px) {
      margin: 0 -10px;
    }
    &_raysx {
      max-width: 80px;
      @media screen and (min-width: 480px) {
        max-width: 134px;
      }
    }
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__icon-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    width: 25px;
    height: 25px;
    @media screen and (min-width: 1200px) {
      width: 34px;
      height: 34px;
    } 
    &-text {
      position: absolute;
      top: -75%;
      left: 50%;
      color: $color-white;
      font: $semi-bold 10px / 1 $font;
      padding: 5px 10px;
      background: #1D59F6;
      border-radius: 12px;
      white-space: nowrap;
      transform: translateX(-50%);
      @media screen and (min-width: 1200px) {
        transform: none;
        top: -30%;
        font-size: 12px;
      } 
    }
  }
  &__text {
    text-align: center;
    color: $color-white;
    font: $bold 12px / 1.5 $font;
    @media screen and (min-width: 990px) {
      font-size: 16px;
    }
    &-span {
      font-weight: $regular;
      color: #717584;
    }
  }
  &__column_head &__text {
    text-align: left;
  }
  &__prev-arrow {
    left: -15px;
    top: 50px;
  }
  &__next-arrow {
    top: 50px;
    right: 10px;
  }
}