.companies {
  &__wrapper {
    padding-bottom: 57px;
  }
  &__title {
    text-align: center;
    color: $color-white;
    font: $bold 25px / 1 $font;
    margin-bottom: 25px;
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
    @media screen and (min-width: 990px) {
      font-size: 35px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -20px;
    background: #0A0A0A;
    padding: 20px 0 10px;
  }
  &__item {
    padding: 0 20px;
    margin-bottom: 10px;
    width: calc(100% / 3);
    @media screen and (min-width: 990px) {
      width: auto;
    } 
  }
  &__img {
    filter: grayscale(100%); 
    transition: filter 0.3s ease;
    margin: 0 auto;
    max-height: 104px;
    max-width: 210px;
    width: 100%;
    @media screen and (min-width: 990px) {
      margin: 0;
      &:hover {
        filter: grayscale(0); 
      }
    }
  }
}