.buy {
  &__cover {
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px;
    @media screen and (min-width: 990px) {
      padding-bottom: 40px;
    }
  }
  &__img-wrapper {
    display: none;
    position: absolute;
    top: -300px;
    left: -770px;
    width: 1037px;
    @media screen and (min-width: 1600px) {
      display: block;
    }
    &_right {
      top: -40px;
      left: auto;
      right: -620px;
      width: 709px;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__wrapper {
    position: relative;
  }
  &__title {
    margin-bottom: 25px;
    padding: 0 15px;
    @media screen and (min-width: 1200px) {
      margin-bottom: 50px;
    } 
  }
  &__links {
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -4px 25px;
    @media screen and (min-width: 600px) {
      margin: 0 -7px 25px;
    }
    @media screen and (min-width: 1200px) {
      margin: 0 -7px 50px;
    } 
    &-item {
      padding: 0 4px;
      margin-bottom: 14px;
      @media screen and (min-width: 600px) {
        padding: 0 7px;
      }
    }
    &-link {
      display: block;
      padding: 8px 12px;
      text-align: center;
      color: $color-white;
      font: $bold 12px / 1 $font;
      background: #252629;
      border-radius: 15px;
      text-decoration: none;
      @media screen and (min-width: 600px) {
        font-size: 14px;
      }
      @media screen and (min-width: 990px) {
        font-size: 16px;
        padding: 12px 20px;
      }
      @media screen and (min-width: 1200px) {
        &:hover,
        &:active {
          text-decoration: underline;
        }
      }
    }
  }
  &__tabs-nav {
    margin-bottom: 16px;
  }
  &__tabs {
    margin-bottom: 50px;
  }
}