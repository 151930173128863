.distribution {
  &__cover {
    padding-top: 50px;
    padding-bottom: 35px;
    @media screen and (min-width: 990px) {
      padding-bottom: 70px;
    }
  }
  &__title {
    margin-bottom: 25px;
    @media screen and (min-width: 600px) {
      margin-bottom: 40px;
    }
    @media screen and (min-width: 990px) {
      margin-bottom: 80px;
    }
  }
  &__table {
    margin-bottom: 25px;
  }
}