.tokenomics {
  &__cover {
    padding-top: 65px;
    padding-bottom: 80px;
    @media screen and (min-width: 1200px) {
      padding-top: 0;
      padding-bottom: 130px;
    }
  }
  &__wrapper {
    margin: 0 -25px;
    @media screen and (min-width: 1200px) {
      display: flex;
    }
  }
  &__left-block {
    padding: 0 25px;
    margin-bottom: 50px;
    width: 100%;
    @media screen and (min-width: 768px) {
      display: flex;
    }
    @media screen and (min-width: 1200px) {
      width: 33%;
      margin-bottom: 0;
      display: block;
    }
  }
  &__right-block {
    padding: 0 25px;
    margin-top: 15px;
    width: 100%;
    @media screen and (min-width: 1200px) {
      width: 67%;
    }
  }
  &__schedule-block {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @media screen and (min-width: 1200px) {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 40px;
    @media screen and (min-width: 1200px) {
      margin-bottom: 81px;
    }
  }
  &__schedule {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    @media screen and (min-width: 1200px) {
      width: 87%;
    }
    &-img {
      width: 100%;
      max-width: none;
    }
  }
  &__info-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px 25px;
    @media screen and (min-width: 1200px) {
      flex-direction: column;
    }
  }
  &__rax {
    position: relative;
    border-radius: 19px;
    margin: 0 6px 12px;
    padding: 1px;
    overflow: hidden;
    flex: 1 1 auto;
    @media screen and (min-width: 480px) {
      flex: 0 1 auto;
      width: calc(50% - 12px);
    } 
    @media screen and (min-width: 600px) {
      flex: 1 1 auto;
      width: auto;
      margin: 0 6px 7px;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg,#27e55c 0%, #587bff 50%, #b518ff 100%);
      z-index: -1;
    }
    @media screen and (min-width: 1200px) {
      margin: 0 3px 13px;
    } 
    &-content {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 9px 1px 5px 1px;
      background: $color-black;
      border-radius: 19px;
      @media screen and (min-width: 990px) {
        padding: 13px 11px 9px 9px;
      }
    }
    &-title {
      display: none;
      padding: 0 8px;
      margin-right: auto;
      color: $color-white;
      font: $bold 13px / 1.4 $font;
      @media screen and (min-width: 768px) {
        display: block;
      }
      @media screen and (min-width: 990px) {
        font-size: 16px;
      }
    }
    &-icon {
      margin: 0 6px 3px;
      border-radius: 50%;
      background: linear-gradient(122.71deg, #9225FF 11.73%, #00FF66 129.28%);
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media screen and (min-width: 768px) {
        margin: 0 8px 3px;
      }
      &-text {
        text-transform: uppercase;
        color: $color-white;
        font: $black 13px / 1.4 $font;
      }
    }
    &-text {
      margin: 0 6px;
      @media screen and (min-width: 768px) {
        margin: 0 8px;
      }
    }
    &-top-text {
      color: $color-white;
      font: $bold 20px / 1 $font;
      margin-bottom: 3px;
      @media screen and (min-width: 990px) {
        font-size: 27px;
      }
    }
    &-bottom-text {
      color: $color-white;
      font: $bold 13px / 1 $font;
      margin-bottom: 3px;
      @media screen and (min-width: 990px) {
        display: none;
      }
    }
  }
  &__info {
    padding: 0 6px;
    margin-bottom: 12px;
    flex: 1 1 auto;
    @media screen and (min-width: 480px) {
      width: 50%;
      flex: 0 1 auto;
    }
    @media screen and (min-width: 600px) {
      width: auto;
      flex: 1 1 auto;
      padding: 0 3px;
      margin-bottom: 7px;
    }
    @media screen and (min-width: 1200px) {
      margin-bottom: 13px;
    } 
    &-content {
      padding: 10px 12px 8px 12px;
      background: rgba(31, 33, 45, 0.8);
      border-radius: 15px;
      height: 100%;
      @media screen and (min-width: 600px) {
        min-height: 66px;
      }
      @media screen and (min-width: 990px) {
        padding: 18px 18px 13px 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &-list {
      display: flex;
      margin: 0 -6px;
    }
    &-item {
      position: relative;
      margin: 0 6px 3px;
      color: $color-white;
      font: $light 16px / 1.4 $font;
      padding-left: 25px;
      @media screen and (min-width: 990px) {
        font-size: 23px;
      }
    }
    &-img-wrapper {
      position: absolute;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      @media screen and (min-width: 990px) {
        top: 5px;
      }
    }
    &__img {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-count {
      color: $color-white;
      font: $light 16px / 1 $font;
      margin-bottom: 8px;
      @media screen and (min-width: 990px) {
        font-size: 23px;
      }
    }
    &-description {
      color: $color-white;
      font: $semi-bold 13px / 1 $font;
      margin-bottom: 8px;
      @media screen and (min-width: 990px) {
        order: -1;
        font-size: 16px;
      }
    }
  }
  &__pools {
    display: none;
    font: $bold 20px / 1.31 $font;
    text-align: center;
    margin-bottom: 15px;
    background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #587BFF;
    @media screen and (min-width: 1200px) {
      display: block;
    }
  }
  &__constituents {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
  }
  &__constituent {
    width: 100%;
    padding: 0 25px;
    margin-bottom: 30px;
    @media screen and (min-width: 600px) {
      width: 50%;
    }
  }
}