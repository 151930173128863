.news {
  &__wrapper {
    padding-top: 18px;
    padding-bottom: 75px;
    @media screen and (min-width: 990px) {
      padding-bottom: 150px;
    }
  }
  &__title {
    color: rgba(171, 172, 199, 0.12);
    font: $bold 45px / 1 $font;
    margin-bottom: -20px;
    @media screen and (min-width: 375px) {
      font-size: 55px;
    }
    @media screen and (min-width: 600px) {
      font-size: 85px;
    }
    @media screen and (min-width: 768px) {
      font-size: 110px;
    }
    @media screen and (min-width: 990px) {
      font-size: 145px;
      margin-bottom: -60px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 180px;
    }
    @media screen and (min-width: 1360px) {
      font-size: 205px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    background: #0A0A0A;
    padding: 20px 0 5px;
    @media screen and (min-width: 990px) {
      margin: 0 -8px;
    }
  }
  &__item {
    width: 50%;
    padding: 0 6px;
    margin-bottom: 16px;
    @media screen and (min-width: 480px) {
      width: 33.3333333333%;
    }
    @media screen and (min-width: 990px) {
      width: 25%;
      padding: 0 8px;
    }
    @media screen and (min-width: 1200px) {
      width: 20%;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    height: 65px;
    filter: grayscale(100%); 
    transition: filter 0.3s ease;
    padding: 0 20px;
    @media screen and (min-width: 990px) {
      height: 92px;
    } 
    &_btc {
      background: linear-gradient(99.11deg, #13B0B4 2.18%, #0091AF 100%);
    }
    &_black {
      background: #000;
    }
    &_cmc {
      background: linear-gradient(45deg, rgb(24, 77, 255), rgb(41, 102, 255));
    }
    &_grey {
      background: #F4F4F4;
    }
    &_dark {
      background: #171F2C;
    }
    &_violet {
      background: #400190;
    }
    &_orange {
      background: linear-gradient(92.47deg, #F80278 1.82%, #E75C0E 100.54%);
    }
    &_taupe-gray {
      background: #272F3A;
    }
    &_white {
      background: #FEFEFE;
    }
    &_bloomberg {
      background: linear-gradient(104.28deg, #F61851 4.8%, #154CDC 27.41%);
    }
    &_blue {
      background: #093967;
    }
    @media screen and (min-width: 990px) {
      &:hover {
        filter: grayscale(0); 
      }
    }
  }
  &__img {
    transition: filter 0.3s ease;
    max-width: 178px;
    max-height: 55px;
    width: 100%;
  }
}