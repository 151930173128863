.key {
  &__cover {
    padding-top: 30px;
    padding-bottom: 35px;
    @media screen and (min-width: 990px) {
      padding-top: 70px;
      padding-bottom: 80px;
    }
  }
  &__title {
    margin-bottom: 22px;
    @media screen and (min-width: 480px) {
      margin-bottom: 35px;
    }
    @media screen and (min-width: 990px) {
      margin-bottom: 62px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  &__item {
    padding: 0 16px;
    margin-bottom: 19px;
    width: 100%;
    min-height: 54px;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @media screen and (min-width: 990px) {
      min-height: 68px;
    } 
    &-content {
      padding: 10px 24px;
      border-radius: 15px;
      background: #212127;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      @media screen and (min-width: 990px) {
        border-radius: 21px;
      } 
      &_black {
        background: #14151B;
      }
    }
    &-rax-bg {
      height: 100%;
      padding: 1px;
      background: linear-gradient(90deg, #27e55c 0%, #587bff 50%, #b518ff 100%);
      border-radius: 15px;
      @media screen and (min-width: 990px) {
        border-radius: 21px;
      } 
    }
    &-title {
      color: $color-white;
      font: $semi-bold 13px / 1.4 $font;
      margin-right: 10px;
      @media screen and (min-width: 990px) {
        font-size: 16px;
      }
    }
    &-text {
      color: $color-white;
      font: $light 16px / 1.4 $font;
      @media screen and (min-width: 990px) {
        font-size: 22px;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -7px;
    }
    &-field {
      padding: 0 7px;
      display: flex;
      align-items: center;
    }
    &-img {
      flex: 0 0 auto;
      margin-right: 14px;
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }
  }
  &__rax-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(122.71deg, #9225FF 11.73%, #00FF66 129.28%);
    margin-right: 10px;
    @media screen and (min-width: 990px) {
      width: 40px;
      height: 40px;
    }
  }
  &__rax-text {
    color: $color-white;
    font: $black 11px / 1.4 $font;
    text-align: center;
    @media screen and (min-width: 990px) {
      font-size: 14px;
    }
  }
}