.team {
  &__wrapper {
    padding-top: 50px;
    @media screen and (min-width: 990px) {
      padding-bottom: 60px;
    }
  }
  &__title {
    margin-bottom: 30px;
    font-weight: $bold;
    @media screen and (min-width: 990px) {
      font-weight: $light;
      margin-bottom: 125px;
    }
  }
  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -17px;
  }
  &__item {
    width: 100%;
    margin-bottom: 27px;
    padding: 0 17px;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @media screen and (min-width: 990px) {
      width: 33.3333333333%;
    }
  }
  &__proscom {
    position: relative;
    border-radius: 26px;		
    padding: 3px;
    margin-bottom: 27px;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; 
      background: linear-gradient(90deg,#27e55c 0%, #587bff 50%, #b518ff 100%);
      z-index: -1;
    }
    &-content {
      background: #131418;
      padding: 26px 25px 15px;
      display: flex;			
      align-items: center;		
  	  flex-wrap: wrap;
      border-radius: 26px;
      @media screen and (min-width: 1200px) {
        padding: 46px 52px 31px;
      }
    }
    &-text {
      padding: 0 10px;		
  	  color: #ffffff;		
  	  font: $regular 20px / 1.1 $font;		
  	  margin-bottom: 15px;
      @media screen and (min-width: 1200px) {
        font-size: 24px;
      }
    }
  }
}