.invite {
  &__cover {
    padding-top: 40px;
    padding-bottom: 30px;
    @media screen and (min-width: 990px) {
      padding-top: 104px;
      padding-bottom: 90px;
    }
  }
  &__title {
    max-width: none;
    margin-bottom: 28px;
  }
  &__subtitle {
    color: #717584;
    font: $medium 13px / 1.4 $font;
    text-align: center;
    max-width: 1096px;
    margin: 0 auto 25px;
    @media screen and (min-width: 600px) {
      font-size: 15px;
      margin: 0 auto 40px;
    }
    @media screen and (min-width: 990px) {
      font-size: 18px;
      margin: 0 auto 60px;
    }
  }
  &__steps {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px 5px;
    @media screen and (min-width: 768px) {
      margin: 0 -17px;
    }   
    &-item {
      padding: 0 6px;
      width: 50%;
      margin-bottom: 12px;
      @media screen and (min-width: 768px) {
        padding: 0 17px;
        margin-bottom: 34px;
      }
      @media screen and (min-width: 990px) {
        width: 33.3333333333%;
      }
      &_big {
        width: 100%;
        @media screen and (min-width: 990px) {
          width: 33.3333333333%;
        }
      }
    }
  }
  &__step {
    height: 100%;
    background: #202127;
    border-radius: 30px;
    padding: 22px;
    @media screen and (min-width: 600px) {
      padding: 37px;
    }
    &-title {
      font: $bold 30px / 1 $font;
      margin-bottom: 13px;
      background: linear-gradient(95.5deg, #587BFF 0.5%, #B518FF 33.98%, #B518FF 97.9%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (min-width: 480px) {
        font-size: 35px;
      }
      @media screen and (min-width: 990px) {
        font-size: 42px;
      }
    }
    &-text {
      color: $color-white;
      font: $bold 13px / 1.5 $font;
      @media screen and (min-width: 480px) {
        font-size: 15px;
      }
      @media screen and (min-width: 990px) {
        font-size: 18px;
      }
    }
  }
  &__info {
    margin-bottom: 34px;
    border-radius: 24px;
    padding: 1px;
    background: linear-gradient(90deg,#27e55c 0%, #587bff 50%, #b518ff 100%);
    &-text {
      background: #202127;
      color: $color-white;
      text-align: center;
      padding: 20px;
      font: $bold 14px / 1.1 $font;
      border-radius: 24px;
      @media screen and (min-width: 480px) {
        font-size: 16px;
      }
      @media screen and (min-width: 990px) {
        font-size: 18px;
      }
    }
    &-span {
      background: linear-gradient(95.5deg, #587BFF 0.5%, #B518FF 33.98%, #B518FF 97.9%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}