.pull {
  &__icon {
    width: 22px;
    height: 20px;
    position: relative;
    div {
      border-radius: 4px;
      width: 100%;
      height: 3px;
      background: $color-white;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.3s ease;
      &:nth-child(2) {
        top: 9px;
      }
      &:last-child {
        top: 18px;
      }
    }
  }
  &.active {
    .pull__icon {
      div {
        transform: translateY(9px) rotate(-45deg);
        &:nth-child(2) {
          display: none;
        }
        &:last-child {
          transform: translateY(-9px) rotate(45deg);
        }
      }
    }
  }
}