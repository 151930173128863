.backers {
  &__cover {
    padding-top: 52px;
    padding-bottom: 25px;
    @media screen and (min-width: 990px) {
      padding-top: 93px;
      padding-bottom: 80px;
    }
  }
  &__title {
    color: #fff;		
    font: $bold 25px / 1.1 $font;		
    text-align: center;		
    margin-bottom: 43px;
    @media screen and (min-width: 768px) {
      font-size: 35px;
    }
  }
  &__list {
    display: flex;		
    flex-wrap: wrap;		
    justify-content: center;		
    margin: 0 -10px;
    @media screen and (min-width: 990px) {
      margin: 0 -25px;
    }
  }
  &__item {
    padding: 0 10px;		
    margin-bottom: 25px;		
    width: 100%;		
    display: flex;
    @media screen and (min-width: 375px) {
      width: 50%;
    }
    @media screen and (min-width: 480px) {
      width: 33.3333333333%;
    }
    @media screen and (min-width: 990px) {
      padding: 0 25px;		
      width: 25%;
    }
  }
  &__basker {
    width: 100%;		
    padding: 5px 10px;		
    max-height: 109px;		
    align-items: center;
    @media screen and (min-width: 990px) {
      padding: 17px 27px;
    }
  }
  &__img {
    max-width: none;		
    width: 100%;		
    height: 100%;
  }
}