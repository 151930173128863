.timer {
  background: #0F1017;
  border-radius: 15px;
  padding: 27px;
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -25px;
  }
  &__time {
    position: relative;
    padding: 0 25px;
    &:before {
      content: ':';
      display: block;
      position: absolute;
      left: -5px;
      top: 0;
      color: #414248;
      font: $medium 50px / 1.2 $font;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  &__count {
    color: $color-white;
    font: $medium 50px / 1.2 $font;
    text-align: center;
  }
  &__description {
    color: $color-white;
    font: $medium 14px / 1.2 $font;
    text-align: center;
  }
}