.case {
  position: relative;
  height: 100%;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -30px;
    height: 120%;
    width: 160%;
    opacity: 0.4;
    filter: blur(110px);
    border-radius: 50%;
    left: 54px;
    z-index: -1;
  }
  &_blue {
    &:before {
      background: $color-blue;
      @media screen and (min-width: 768px) {
        right: 54px;
      } 
    }
  }
  &_violet {
    &:before {
      background: $color-violet;
      left: 54px;
    }
  }
  &_green {
    &:before {
      background: $color-green;
      @media screen and (min-width: 768px) {
        right: 54px;
      } 
    }
  }
  &_orange {
    &:before {
      background: $color-orange;
      left: 54px;
    }
  }
  &__content {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.89) 100%);
    backdrop-filter: blur(75px);
    border-radius: 25.9255px;
    padding: 28px 23px 16px;
    height: 100%;
    @media screen and (min-width: 768px) {
      padding: 28px 28px 34px;
    }
  }
  &__title {
    color: $color-white;
    font: $bold 25px / 1.2 $font;
    margin-bottom: 16px;
    @media screen and (min-width: 768px) {
      font-size: 32px;
      margin-bottom: 27px;
    }
  }
  &__text {
    p {
      color: $color-white;
      font: $regular 13px / 1.3 $font;
      margin-bottom: 18px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
}