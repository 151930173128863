.cases {
  &__wrapper {
    position: relative;
    padding-bottom: 25px;
    @media screen and (min-width: 990px) {
      padding-bottom: 115px;
    }
  }
  &__head {
    margin-bottom: 62px;
    text-align: left;
    @media screen and (min-width: 768px) {
      text-align: center;
    }
  }
  &__title {
    font-size: 35px;
  }
  &__description {
    color: #717584;
    font-size: 16px;
    font-weight: 500;
    margin: 0;

    @media screen and (min-width: 990px) {
      font-size: 22px;
    }
  }
  &__list {
    margin: 0 -12px;
    max-width: 636px;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    } 
    &-item {
      width: 264px;
      padding: 0 12px;
      margin-bottom: 39px;
      @media screen and (min-width: 768px) {
        width: 50%;
        margin-bottom: 24px;
      }
    }
  }
  &__img-1 {
    position: absolute;
    top: 390px;
    right: -20px;
    width: 161px;
    max-width: none;
    transform: rotate(10deg);
    @media screen and (min-width: 768px) {
      width: 264px;
      transform: rotate(-12deg);
      top: 90px;
      left: 0;
      right: auto;
    }
  }
  &__img-2 {
    position: absolute;
    top: 235px;
    right: -20px;
    width: 125px;
    max-width: none;
    transform: rotate(-6deg);
    @media screen and (min-width: 768px) {
      top: 330px;
      left: -105px;
      width: 186px;
      right: auto;
    }
  }
  &__img-3 {
    position: absolute;
    top: -10px;
    right: -80px;
    width: 215px;
    max-width: none;
    transform: rotate(-12deg);
    @media screen and (min-width: 768px) {
      transform: rotate(8deg);
      top: 234px;
      left: 60px;
      right: auto;
      width: 307px;
    }
  }
  &__img-4 {
    position: absolute;
    top: 850px;
    right: -45px;
    width: 250px;
    max-width: none;
    transform: rotate(8deg);
    @media screen and (min-width: 768px) {
      top: 536px;
      left: -45px;
      width: 324px;
      right: auto;
    }
  }
  &__img-5 {
    position: absolute;
    top: 560px;
    right: -75px;
    width: 247px;
    max-width: none;
    transform: rotate(-18deg);
    @media screen and (min-width: 768px) {
      top: 95px;
      right: -75px;
      width: 362px;
      transform: rotate(4deg);
    }
  }
  &__img-6 {
    position: absolute;
    top: 1070px;
    right: -110px;
    width: 200px;
    max-width: none;
    transform: rotate(-20deg);
    @media screen and (min-width: 600px) {
      right: -40px;
    }
    @media screen and (min-width: 768px) {
      top: 351px;
      right: -180px;
      width: 294px;
      transform: rotate(9deg);
    }
  }
  &__img-7 {
    position: absolute;
    top: 740px;
    right: -20px;
    width: 88px;
    max-width: none;
    transform: rotate(-5deg);
    @media screen and (min-width: 768px) {
      top: 352px;
      right: 150px;
      width: 152px;
      transform: rotate(6deg);
    }
  }
  &__img-8 {
    position: absolute;
    top: 1225px;
    right: -63px;
    width: 237px;
    max-width: none;
    transform: rotate(-6deg);
    @media screen and (min-width: 768px) {
      top: 538px;
      right: -63px;
      width: 350px;
    }
  }
}