.roadmap-item {
  position: relative;
  padding-top: 30px;
  @media screen and (min-width: 990px) {
    padding-top: 45px;
  }
  &__year {
    color: $color-white;
    font: $light 25px / 1 $font;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (min-width: 990px) {
      font-size: 38px;
    }
  }
  &__quarter {
    color: $color-white;
    font: $bold 20px / 1.3 $font;
    margin-bottom: 15px;
    @media screen and (min-width: 990px) {
      font-size: 24px;
    }
  }
  &__point {
    background: $color-white;
    border: 14px solid #090A0E;
    backdrop-filter: blur(41.2903px);
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-left: -8px;
    @media screen and (min-width: 990px) {
      width: 43px;
      height: 43px;
      border-width: 17px;
      margin-bottom: 35px;
    }
  }
  &__list {
    margin-bottom: 27px;
    &-item {
      position: relative;
      color: $color-white;
      font: $regular 13px / 1.3 $font;
      padding-left: 20px;
      margin-bottom: 10px;
      @media screen and (min-width: 990px) {
        font-size: 15px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $color-white;
        @media screen and (min-width: 990px) {
          top: 7px;
        }
      }
    }
  }
}