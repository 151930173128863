.title {
  font: $light 25px / 1.2 $font;
  text-align: center;
  max-width: 813px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 600px) {
    font-size: 30px;
  }
  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
  @media screen and (min-width: 990px) {
    font-size: 55px;
  }
}