.challenge {
  background: rgba(32, 41, 58, 0.77);
  backdrop-filter: blur(71.9002px);
  border-radius: 38px;
  height: 100%;
  padding: 5px 25px 28px;
  @media screen and (min-width: 1200px) {
    padding: 5px 30px 28px;
  }
  &__img-wrapper {
    position: relative;
    margin: 0 auto;
    width: 80%;
    padding-top: 80%;
    animation: bounce 3s linear infinite;
    @media screen and (min-width: 480px) {
      padding-top: 50%;
      width: 50%;
    }
    @media screen and (min-width: 600px) {
      padding-top: 100%;
      width: auto;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 65%;
      height: 65%;
      background: rgba(85, 33, 206, 0.62);
      filter: blur(65px);
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80%;
    max-width: none;
  }
  &__content {
    text-align: center;
  }
  &__title {
    color: $color-white;
    font: $bold 17px / 1.15 $font;
    margin-bottom: 15px;
    @media screen and (min-width: 1200px) {
      font-size: 28px;
    }
  }
  &__text {
    margin-bottom: 20px;
    p {
      color: $color-white;
      margin-bottom: 10px;
      font: $regular 12px / 1.5 $font;
      @media screen and (min-width: 1200px) {
        font-size: 16px;
      }
    }
  }
}