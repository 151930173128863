.sale {
  position: relative;
  background: linear-gradient(90deg,#b518ff 0%, #587bff 50%, #27e55c 100%);
  border-radius: 25px;
  padding: 1px;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 38px;
    width: 80%;
    height: 90%;
    border-radius: 50%;
    background: #8123F9;
    filter: blur(85px);
    z-index: -1;
  }
  &:after {
    height: 80%;
    width: 60%;
    bottom: 20px;
    background: #2FD5B7;
    filter: blur(92px);
    left: auto;
    right: 38px;
  }
  &__wrapper {
    padding: 27px 15px 10px 15px;
    background: #1E1F2A;
    border-radius: 25px;
    @media screen and (min-width: 480px) {
      padding: 27px 20px 10px 20px;
    }
    @media screen and (min-width: 990px) {
      padding: 44px 37px 19px 37px;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
  }
  &__left {
    padding: 0 20px;
    width: 100%;
    @media screen and (min-width: 990px) {
      width: 60%;
    } 
  }
  &__right {
    padding: 0 20px;
    width: 100%;
    @media screen and (min-width: 990px) {
      width: 40%;
    } 
  }
  &__title {
    color: $color-white;
    font: $bold 18px / 1.1 $font;
    margin-bottom: 26px;
    text-align: center;
    @media screen and (min-width: 480px) {
      font-size: 22px;
      margin-bottom: 34px;
    }
    @media screen and (min-width: 990px) {
      text-align: left;
    } 
  }
  &__info {
    display: flex;
    margin: 0 -4px 10px;
    @media screen and (min-width: 480px) {
      margin: 0 -7px 17px;
    }
    &-item {
      padding: 0 4px;
      width: 50%;
      @media screen and (min-width: 480px) {
        padding: 0 7px;
      }
    }
    &-count {
      background: #1D59F6;
      border-radius: 12px;
      text-align: center;
      color: $color-white;
      font: $regular 16px / 1.2 $font;
      padding: 7px 15px;
      margin-bottom: 10px;
      @media screen and (min-width: 480px) {
        font-size: 18px;
        margin-bottom: 20px;
      }
      @media screen and (min-width: 600px) {
        font-size: 20px;
      } 
    }
    &-description {
      color: $color-white;
      text-align: center;
      font: $medium 12px / 1.2 $font;
      margin-bottom: 10px;
      @media screen and (min-width: 480px) {
        font-size: 14px;
      }
      @media screen and (min-width: 600px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }
  &__progress {
    padding-top: 20px;
    margin-bottom: 36px;
    @media screen and (min-width: 600px) {
      margin-bottom: 46px;
    }
    &-info {
      display: flex;
      justify-content: space-between;
      margin: 0 -5px;
    }
    &-item {
      padding: 0 5px;
      color: $color-white;
      font: $regular 12px / 1.2 $font;
      @media screen and (min-width: 480px) {
        font-size: 14px;
      }
    }
  }
  &__line {
    position: relative;
    width: 100%;
    height: 13px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.15);
    margin-bottom: 11px;
    @media screen and (min-width: 480px) {
      height: 17px;
    } 
    &-progress{
      position: absolute;
      top: 2px;
      left: 0;
      height: 9px;
      background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
      border-radius: 6px;
      @media screen and (min-width: 480px) {
        top: 4px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: -2px;
        top: -6px;
        width: 2px;
        height: 21px;
        background: $color-white;
      }
    }
    &-count {
      position: absolute;
      top: -20px;
      color: $color-white;
      font: $regular 12px / 1.2 $font;
      transform: translateX(-50%);
    }
  }
  &__footer {
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px 6px;
      @media screen and (min-width: 600px) {
        margin: 0 -4px 13px;
      }
    }
    &-item {
      padding: 0 4px;
      margin-bottom: 8px;
    }
  }
  &__bsc {
    background:  rgba(255, 255, 255, 0.1);
    border-radius: 13px;
    padding: 7px 12px;
    &-content {
      display: flex;
      align-items: center;
    }
    &-img {
      flex: 0 0 auto;
      width: 15px;
      margin-right: 5px;
      @media screen and (min-width: 480px) {
        width: 19px
      }
    }
    &-copy {
      padding: 0 3px;
      flex: 0 0 auto;
      width: 21px;
      height: 16px;
      fill: $color-white;
      opacity: 0.7;
      cursor: pointer;
      transition: opacity 0.3s ease;
      @media screen and (min-width: 480px) {
        width: 24px;
        height: 19px;
      }
      @media screen and (min-width: 1200px) {
        &:hover,
        &:active {
          opacity: 1;
        }
      }
    }
    &-label {
      position: relative;
      display: flex;
      align-items: center;
    }
    &-span {
      display: inline-block;
      color: #8E91A5;
      font: $semi-bold 10px / 1.2 $font;
      @media screen and (min-width: 480px) {
        font-size: 13px;
      }
    }
    &-address,
    &-input {
      display: block;
      background: none;
      outline: none;
      border: none;
      color: $color-white;
      font: $semi-bold 10px / 1.2 $font;
      width: 100%;
      padding: 1px 3px;
      @media screen and (min-width: 480px) {
        font-size: 13px;
        width: 80px;
      }
    }
    &-input {
      right: 0;
      position: absolute;
      z-index: -2;
    }
  }
  &__coinmarketcap {
    display: block;
    text-decoration: none;
    background:  rgba(255, 255, 255, 0.1);
    border-radius: 13px;
    padding: 7px 12px;
    @media screen and (min-width: 1200px) {
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
    &-content {
      display: flex;
      align-items: center;
    }
    &-img{
      width: 15px;
      margin-right: 5px;
      @media screen and (min-width: 480px) {
        width: 20px;
      }
    }
    &-text {
      color: $color-white;
      font: $semi-bold 10px / 1.2 $font;
      @media screen and (min-width: 480px) {
        font-size: 13px;
      }
    }
  }
  &__vesting {
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 13px;
    padding: 10px 14px;
    color: $color-white;
    font: $regular 10px / 1.2 $font;
    margin-bottom: 20px;
    @media screen and (min-width: 480px) {
      font-size: 13px;
    } 
  }
  &__form {
    height: 100%;
  }
  &__exchange {
    position: relative;
    margin-bottom: 10px;
    &-info {
      display: flex;
      justify-content: space-between;
      margin: 0 -5px;
    }
    &-item {
      padding: 0 5px;
      margin-bottom: 10px;
    }
    &-text {
      color: $color-white;
      font: $medium 12px / 1.2 $font;
      @media screen and (min-width: 480px) {
        font-size: 13px;
      }
    }
    &-title {
      color: #8E91A5;
    }
  }
  &__field {
    margin-bottom: 17px;
    background: #0F1017;
    border-radius: 12px;
    padding: 20px 18px;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -5px;
    }
    &-select {
      padding: 0 5px;
      flex: 0 0 auto;
    }
  }
  &__input-wrapper {
    padding: 0 5px;
  }
  &__input {
    display: block;
    border: 0;
    text-align: right;
    background: none;
    color: $color-white;
    font: $bold 18px / 1.2 $font;
    padding: 0;
    outline: none;
    width: 100%;
  }
  &__arrow-wrapper {
    position: absolute;
    top: 47px;
    left: 50%;
    transform: translateX(-50%);
    background: #1E1F2A;
    border-radius: 50%;
    padding: 11px;
    width: 54px;
    height: 54px;
  }
  &__arrow {
    position: relative;
    width: 32px;
    height: 32px;
    background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
    border-radius: 50%;
    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 15px;
      background: $color-white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:after {
      content: '';
      display: block; 
      width: 10px;
      height: 10px;
      border-left: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &__rax {
    display: flex;
    align-items: center;
    color: #FFF;
    font: $bold 18px / 1 $font;
    @media screen and (min-width: 480px) {
      font-size: 20px;
    }
    &-img {
      flex: 0 0 auto;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 7px;
    }
  }
  &__btn {
    display: block;
    width: 100%;
    text-align: center;
    border: none;
    text-decoration: none;
    cursor: pointer;
    background: linear-gradient(95.5deg, #27E65C 0.5%, #587BFF 50.22%, #B518FF 97.9%);
    border-radius: 15px;
    color: $color-white;
    font: $bold 16px / 1.2 $font;
    padding: 15px;
    margin-bottom: 14px;
    &-description {
      color: $color-white;
      font: $regular 10px / 1.5 $font;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (min-width: 480px) {
        font-size: 11px;
      }
    }
  }
  &__wallets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -7px;
    @media screen and (min-width: 480px) {
      margin: 0 -10px;
    } 
    &-item {
      padding: 0 7px;
      margin-bottom: 20px;
      @media screen and (min-width: 480px) {
        padding: 0 10px;
      }
    }
  }
  &__timer {
    margin-bottom: 34px;
  }
}