.person {
  background: rgba(31, 33, 45, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 27px 23px 22px;
  height: 100%;
  @media screen and (min-width: 1200px) {
    padding: 27px;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
  &__img-wrapper {
    width: 122px;
    height: 122px;
    margin: 0 15px 30px 15px;
    flex: 0 0 auto;
    @media screen and (min-width: 1200px) {
      width: 218px;
      height: 218px;
      margin: -85px 15px 30px 15px;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  &__socials {
    margin: 0 15px;
  }
  &__name {
    color: $color-white;
    font: $bold 25px / 1 $font;
    margin-bottom: 18px;
    @media screen and (min-width: 1200px) {
      font-size: 30px;
    }
  }
  &__position {
    color: #40FF99;
    font: $bold 14px / 1 $font;
    margin-bottom: 21px;
    @media screen and (min-width: 1200px) {
      font-size: 18px;
    }
  }
  &__text {
    p {
      color: $color-white;
      font: $regular 13px / 1.4 $font;
      margin-bottom: 20px;
      @media screen and (min-width: 1200px) {
        font-size: 15px;
      }
    }
  }
}