.scheme {
  &__wrapper {
    padding-top: 10px;
    padding-bottom: 95px;
    @media screen and (min-width: 990px) {
      padding-top: 40px;
      padding-bottom: 170px;
    }
  }
  &__title {
    font-weight: $bold;
    margin-bottom: 21px;
    font-size: 35px;
    @media screen and (min-width: 600px) {
      font-size: 35px;
    }
    @media screen and (min-width: 990px) {
      margin-bottom: 27px;
    }
  }
  &__text {
    position: relative;
    text-align: center;
    max-width: 1137px;
    margin: 0 auto 20px;
    p {
      color: #717584;
      font: $regular 13px / 1.3 $font;
      margin-bottom: 10px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
  &__full {
    text-align: center;
    margin-bottom: 25px;
    @media screen and (min-width: 990px) {
      display: none;
    }
  }
  &__img-wrapper {
    position: relative;
    margin: -10% -12% -8% -15%;
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__trade-fee {
    display: none;
    margin: 0;
    text-align: center;
    font-size: 10px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.80);
    @media screen and (min-width: 1200px) {
      bottom: 120px;
    }
    @media screen and (min-width: 990px) {
      left: 40%;
      bottom: 90px;
      font-size: 14px;
    }
    @media screen and (min-width: 768px) {
      bottom: 60px;
    }
    @media screen and (min-width: 599px) {
      display: block;
      position: absolute;
      left: 40%;
      bottom: 45px;
    }
  }
  &__trade-fee-mob {
    display: block;
    text-align: center;
    font-size: 10px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.80);
    margin: 0 0 10px;

    @media screen and (min-width: 599px) {
      display: none;
    }
  }
  &__description {
    color: #717584;
    font: $regular 13px / 1.3 $font;
    margin-bottom: 15px;
    text-align: center;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}