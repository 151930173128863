$font: 'Work Sans', sans-serif;
$font-second: 'Open Sans', sans-serif;

$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

$color-white: #FFF;
$color-black: #090A0E;
$color-violet: #7F00FF;
$color-yellow: #FFCC16;
$color-blue: #275BDF;
$color-pink: #E1179C;
$color-green: #00CA75;
$color-orange: #FF7448;

