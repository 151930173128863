.burning {
  background: #151721;
  @media screen and (min-width: 990px) {
    background: rgba(28, 29, 35, 0.6);
  }
  &__cover {
    padding-top: 45px;
    padding-bottom: 45px;
    @media screen and (min-width: 990px) {
      padding-top: 89px;
      padding-bottom: 100px;
    }
  }
  &__title {
    position: relative;
    z-index: 1;
  }
  &__wrapper {
    position: relative;
  }
  &__img-wrapper {
    position: relative;
    display: block;
    margin: -10% -30% 0 -20%;
    @media screen and (min-width: 990px) {
      width: 33%;
      margin: 0 auto;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__text-wrapper {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    top: 100px;
    left: 0;
    height: 100%;
    @media screen and (min-width: 990px) {
      display: flex;
    }
  }
  &__text {
    position: relative;
    display: flex;
    width: 45%;
    text-align: center;
    justify-content: flex-start;
    &::before {
      content: '';
      display: block;
      width: 47%;
      height: 1px;
      background: linear-gradient(82.06deg, #7F00FF -2.67%, rgba(255, 255, 255, 0.6) 91.35%);
      position: absolute;
      bottom: 40%;
      right: -10%;
      transform: rotate(58deg);
    }
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid $color-white;
      position: absolute;
      bottom: -17%;
      right: -1%;
    }
    &_first {
      &:before {
        width: 39%;
        bottom: 36%;
        transform: rotate(67deg);
        @media screen and (min-width: 1200px) {
          transform: rotate(58deg);
          bottom: 40%;
          width: 47%;
        }
      }
    }
    &_second {
      &:before {
        width: 50%;
        transform: rotate(129deg);
        right: auto;
        left: -20%;
        bottom: 33%;
        @media screen and (min-width: 1200px) {
          width: 63%;
          bottom: 34%;
          transform: rotate(137deg);
        }
      }
      &:after {
        bottom: -25%;
        right: auto;
        left: -14%;
      }
    }
    &_third {
      &:before {
        width: 18%;
        transform: rotate(43deg);
        right: 8%;
        bottom: 69%;
        @media screen and (min-width: 1200px) {
          width: 34%;
          transform: rotate(25deg);
        }
      }
      &:after {
        bottom: 45%;
        right: 7%;
      }
    }
    &_fourth {
      &:before {
        width: 30%;
        transform: rotate(147deg);
        right: auto;
        left: 5%;
        bottom: 65%;
        @media screen and (min-width: 1200px) {
          width: 43%;
          transform: rotate(155deg);
        }
      }
      &:after {
        bottom: 37%;
        right: auto;
        left: 4%;
      }
    }
    &_fifth {
      &:before {
        width: 11%;
        transform: rotate(-36deg);
        right: 9%;
        bottom: auto;
        top: -8%;
        @media screen and (min-width: 1200px) {
          width: 28%;
          transform: rotate(-18deg);
        }
      }
      &:after {
        bottom: auto;
        top: -24%;
        right: 7%;
      }
    }
    &_sixth {
      &:before {
        width: 39%;
        transform: rotate(185deg);
        right: auto;
        left: -10%;
        bottom: auto;
        top: 1%;
        @media screen and (min-width: 1200px) {
          left: -11%;
          width: 56%;
        }
      }
      &:after {
        bottom: auto;
        top: -8%;
        right: auto;
        left: -14%;
      }
    }
    &:nth-child(2n) {
      justify-content: flex-end;
    }
    &-content {
      max-width: 360px;
      width: 100%;
    }
    &_first &-content {
      margin-left: 10%;
      @media screen and (min-width: 1360px) {
        margin-left: 15%;
      }
    }
    &_second &-content {
      margin-right: 15%;
    }
    &-title {
      color: $color-white;
      font: $bold 26px / 1.11 $font;
      margin-bottom: 10px;
    }
    &-description {
      color: #717584;
      font: $medium 15px / 1.17 $font;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    &_first &-description {
      max-width: 280px;
    }
    &_second &-description {
      max-width: 200px;
    }
    &_third &-description {
      max-width: 336px;
    }
    &_fourth &-description {
      max-width: 195px;
    }
    &_sixth &-description {
      max-width: 295px;
    }
  }
}