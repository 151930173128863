.ecosystem-item {
  padding: 20px 20px 10px;
  height: 100%;
  border-radius: 22px;
  display: flex;
  align-items: center;
  @media screen and (min-width: 600px) {
    display: block;
    padding: 10px 20px 20px;
  } 
  &_green {
    background: linear-gradient(270.58deg, #2ADE67 0.44%, #033528 98.23%);
    @media screen and (min-width: 600px) {
      background: linear-gradient(180deg, #033428 0%, #00CB6E 100%);
    }
  }
  &_blue {
    background: linear-gradient(270deg, #275BDF 0%, #0A0A46 100%);
    @media screen and (min-width: 600px) {
      background: linear-gradient(180deg, #0A0944 0%, #275BDF 100%);
    }
  }
  &_violet {
    background: linear-gradient(270.45deg, #7900F3 0.38%, #1A0239 99.68%);
    @media screen and (min-width: 600px) {
      background: linear-gradient(180deg, #15032E 0%, #7F00FF 100%);
    }
  }
  &__img-wrapper {
    position: relative;
    width: 58px;
    margin-bottom: 10px;
    flex: 0 0 auto;
    margin-right: 20px;
    animation: bounce 3s linear infinite;
    @media screen and (min-width: 600px) {
      height: 122px;
      width: auto;
      margin-right: 0;
      margin-bottom: 17px;
    }
  }
  &__img {
    max-width: none;
    width: 100%;
    @media screen and (min-width: 600px) {
      width: auto;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    } 
  }
  &__text {
    color: $color-white;
    font: $bold 13px /1.31 $font;
    margin: 0 auto 10px;
    @media screen and (min-width: 600px) {
      text-align: center;
      max-width: 305px;
      font-size: 15px;
      margin: 0 auto 20px;
    }
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }
}