.product {
  border-radius: 28px;
  //border: 1px solid #24252D;
  background: #1A1B22;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(71px);
  //background: linear-gradient(180deg, #1C1723 0%, #1F212D 100%);
  padding: 26px 14px;
  text-align: center;
  min-height: 315px;
  height: 100%;

  @media screen and (min-width: 990px) {
    padding: 40px 20px;
  }

  &_violet {
    background: linear-gradient(180deg, #15032E 0%, #7F00FF 100%);
    //background: linear-gradient(180deg, #15032E 0%, #7F00FF 100%);

    @media screen and (min-width: 990px) {
      //height: 500px;
      //padding: 25px 30px 15px;
    }
  }
  &_pink {
    background: linear-gradient(180deg, #29052D 0%, #E1169C 100%);
    //background: linear-gradient(180deg, #29052D 0%, #E1169C 100%);

    @media screen and (min-width: 990px) {
      //height: 520px;
      //padding: 40px 20px;
    }
  }
  &_green {
    //background: linear-gradient(180deg, #033428 0%, #00CB6E 100%);
  }
  &_blue {
    //background: linear-gradient(180deg, #0A0944 0%, #275BDF 100%);

    @media screen and (min-width: 990px) {
      //height: 540px;
      //padding: 25px 30px 15px;
    }
  }
  &__chip {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    padding: 6px 12px;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.34);
    font-size: 14px;
    font-weight: 700;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0) 100%);

    @media screen and (min-width: 990px) {
      padding: 11px 20px;
      font-size: 20px;
    }
  }
  &_text {
    background: $color-black;
    text-align: left;
    border: 5px solid $color-white;
    display: flex;
    align-items: center;
  }
  &__img-wrapper {
    margin-bottom: 15px;
    position: relative;
    height: 230px;
    //margin-top: -50px;
    //margin-bottom: -5px;
    animation: bounce 3s linear infinite;
    @media screen and (min-width: 990px) {
      //margin-top: -85px;
      margin-bottom: 0;
    } 
    @media screen and (min-width: 1200px) {
      height: 300px;
      //margin-top: -110px;
      //margin-bottom: -10px;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
  }
  &__title {
    color: $color-white;
    font: $bold 20px / 1.2 $font;
    margin-bottom: 10px;
    @media screen and (min-width: 1200px) {
      font-size: 24px;
    } 
  }
  &__text {
    p {
      color: $color-white;
      font: $medium 14px / 1.17 $font;
      margin-bottom: 15px;
      @media screen and (min-width: 1200px) {
        font-size: 17px;
      }
    }
  }
}