.manifest-v2 {
  display: flex;
  flex-direction: column;

  gap: 32px;

  padding-bottom: 70px;
  padding-top: 70px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 120px;
    padding-top: 210px;
    gap: 80px;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (min-width: 768px) {
      gap: 30px;
    }

    & > div {
      & > p {
        margin-bottom: 0;
        color: #AEB0B7;
        font-size: 13px;
        font-weight: 500;
        line-height: 140%;

        @media screen and (min-width: 768px) {
          font-size: 20px;
          font-weight: 600;
          line-height: 130%;
        }
      }
    }

    & > h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 0;
      margin-right: 20%;

      @media screen and (min-width: 768px) {
        font-size: 88px;
        line-height: 100%;
        margin-right: unset;
      }

      & > .highlight {
        background: var(--Complement-Strong, linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  & > .problems {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    @media screen and (min-width: 768px) {
      gap: 28px;
    }

    & > .problem-item {
      display: flex;
      background: #1A1B22;
      padding: 20px 24px;
      border-radius: 28px;

      gap: 16px;

      @media screen and (min-width: 768px) {
        gap: 24px;
      }

      & > .icon {
        margin: auto 0;
        min-width: 52px !important;
        max-width: 52px !important;
        min-height: 52px !important;
        max-height: 52px !important;

        @media screen and (min-width: 768px) {
          min-width: 84px !important;
          max-width: 84px !important;
          min-height: 84px !important;
          max-height: 84px !important;
        }
      }

      & > p {
        font-size: 15px;
        line-height: 130%;
        font-weight: 600;
        margin-bottom: 0;

        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 120%;
        }

        & > span {
          background: var(--Complement-Strong-Up, linear-gradient(116deg, #27E55C 1.87%, #58ff98 44.47%, #18ffc1 85.33%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}