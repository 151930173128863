.advantage {
  &__img-wrapper {
    margin-bottom: 24px;
    width: 104px;
  }
  &__img {
    max-width: none;
    width: 100%;
  }
  &__title {
    color: $color-white;
    font: $bold 16px / 1.3 $font;
    margin-bottom: 13px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }
  &__description {
    color: #717584;
    font: $regular 13px / 1.3 $font;
    margin-bottom: 13px;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
  &__icons {
    position: relative;
    min-height: 115px;
  }
  &__big-icon {
    opacity: 0.2;
  }
  &__small-icon {
    position: absolute;
    top: 41px;
    left: 0;
  }
}